import { instance } from './index';

export const deleteAccountRequest = async (password: string) => {
  const data = { password: password };
  const response = await instance.post('/user/remove', data);
  return response.data;
};

export const changePasswordRequest = async (currentPassword: string, changedPassword: string) => {
  const data = { currentPassword: currentPassword, changedPassword: changedPassword };
  const response = await instance.patch('/user/change/password', data);
  return response.data;
};
