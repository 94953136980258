export interface ICheckIOTResponse {
  hasAccessToken: boolean;
  userName: string;
  accessToken: string;
  createdDate: string;
}

export interface IDevice {
  id: string;
  name: string;
  deviceType: string;
  hasSubDevices: boolean;
  modelName: string;
  familyId: string;
  category: string;
  online: boolean;
}

export interface ITemperature {
  id: string;
  deviceType: string;
  deviceState: ITemperatureDeviceState;
}

export interface ITemperatureDeviceState {
  temperature: number; // 온도 값 (단위가 필요할 경우, 예: 섭씨 또는 화씨)
  humidity: number; // 습도 값 (퍼센트로 표현되는 경우가 많음)
  battery: number;
}

export interface IControlPlugMiniRequest {
  power?: boolean;
  powerStatus?: string;
  ledlightMode?: string;
  childLock?: boolean;
}

export interface IControlPlugAirconditionerRequest {
  power?: boolean;
  temperature?: string | number;
  fanSpeed?: string;
  mode?: string;
}

export interface IAirconditionerState {
  deviceState: {
    power: string;
    temperature: string;
    mode: number;
    fanSpeed: number;
  };
  id: string;
  deviceType: string;
}

export interface IPlugMiniState {
  deviceState: {
    power: boolean;
    curCurrent: number;
    curPower: number;
    curVoltage: number;
    powerStatus: string;
    ledlightMode: string;
    childLock: boolean;
  };
  id: string;
  deviceType: string;
}

export interface IBulbDeviceState {
  power1: boolean;
  power2: boolean;
  power3: boolean;
}

export interface ZigbeeSwitchProps {
  id: string;
  deviceType: string;
  deviceState: IBulbDeviceState;
}

export enum EAirconditionerMode {
  'cold' = '0',
  'hot' = '1',
  'auto' = '2',
  'light' = '3',
  'humidifier' = '4',
}

export enum EAirconditionerSpeed {
  'auto' = '0',
  'low' = '1',
  'middle' = '2',
  'high' = '3',
}
