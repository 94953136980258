import { getSearchStoreUser } from '@dataTypes/grantAuthority';
import React from 'react';

interface SearchUserItemProps {
  showConfirmModal: (user: getSearchStoreUser) => void;
  user: getSearchStoreUser;
}

const SearchUserItem = ({ user, showConfirmModal }: SearchUserItemProps) => {
  const formattedPhoneNumber = (phoneNumber: string) => {
    const slicedPhone = phoneNumber.slice(0, 7);
    return `${slicedPhone}xxxx`;
  };
  return (
    <div className="list_section_wrapper">
      <div className="name">{user.name}</div>
      <div className="phone">{formattedPhoneNumber(user.phoneNumber)}</div>
      <div className="member_btn" onClick={() => showConfirmModal(user)}>
        변경
      </div>
    </div>
  );
};

export default SearchUserItem;
