import React, { ReactNode } from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

interface CommonHeaderProps {
  children: ReactNode;
  backBtnUrl?: string;
}

const CommonHeader = ({ children, backBtnUrl }: CommonHeaderProps) => {
  const navigation = useNavigate();

  const handleBackClick = () => {
    if (backBtnUrl) {
      navigation(backBtnUrl); // URL로 이동
    } else {
      navigation(-1); // 뒤로 가기
    }
  };

  return (
    <div className="header_wrapper">
      <div className="back_btn" onClick={handleBackClick}>
        <IoMdArrowBack size={'1.5rem'} />
      </div>
      <div className="header_title">{children}</div>
    </div>
  );
};

export default CommonHeader;
