import ChangePasswordMain from '@components/changePassword/ChangePasswordMain';
import CommonHeader from '@components/common/CommonHeader';
import React from 'react';

const ChangePassword = () => {
  return (
    <div id="delete_account_container">
      <CommonHeader>비밀번호 변경</CommonHeader>
      <ChangePasswordMain />
    </div>
  );
};

export default ChangePassword;
