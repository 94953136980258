import { Outlet } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <RecoilRoot>
      <Layout>
        <Outlet />
      </Layout>
    </RecoilRoot>
  );
}

export default App;
