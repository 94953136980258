import React from 'react';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { ko } from 'date-fns/esm/locale';
import 'react-calendar/dist/Calendar.css';
import { Button } from 'react-bootstrap';

interface SelectDateProps {
  show: boolean;
  handleClose: () => void;
  setSelectedDateVal: React.Dispatch<React.SetStateAction<Date>>;
}

const SelectDate = ({ show, handleClose, setSelectedDateVal }: SelectDateProps) => {
  const [pickedDate, setPickedDate] = useState(new Date());

  const calendarHandler = (date: Date) => {
    setPickedDate(date);
  };

  const confirmDateHandler = () => {
    setSelectedDateVal(pickedDate);
    handleClose();
  };

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 시간을 00:00:00:00으로 설정
    return today;
  };

  return (
    <Offcanvas id="select_date_modal_wrapper" show={show} onHide={handleClose} placement="bottom">
      <Offcanvas.Header closeButton className="select_header">
        <Offcanvas.Title>날짜선택</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="select_body">
        <Calendar
          //   className={`${showCalendar && 'active'}`}
          onChange={(date) => calendarHandler(date as Date)}
          formatDay={(locale, date) => dayjs(date).format('DD')}
          value={pickedDate}
          calendarType={'gregory'}
          locale={'kr'}
          tileDisabled={({ date }) => date > getToday()}
          tileClassName={({ date }) => {
            const day = date.getDay();
            if (day === 0) return 'sunday';
            if (day === 6) return 'saturday';
            return null;
          }}
        />

        <div className="confirm_btn_wrapper">
          <div>
            <span className="sub_text">적용값</span>
            <span className="confirm_date">{dayjs(pickedDate).format('YYYY-MM-DD')}</span>
          </div>
          <Button className="apply_btn" onClick={confirmDateHandler}>
            적용하기
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SelectDate;
