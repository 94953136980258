import React from 'react';
import arrow from '@assets/icon/next_arrow_icon.png';
import { perMachineTotalSalesData } from '@dataTypes/perMachineTypes';
import { format, subDays } from 'date-fns';

interface PerMachineItemProps {
  item: perMachineTotalSalesData;
}

const PerMachineItem = ({ item }: PerMachineItemProps) => {
  const today = new Date(item.date);
  const yesterday = subDays(today, 1);
  const formattedYesterday = format(yesterday, 'MM-dd');
  const twoDaysAgo = subDays(today, 2);
  const formattedTwoDaysAgo = format(twoDaysAgo, 'MM-dd');
  const totalSales = item.card + item.cash + item.etc;
  return (
    <div className="item">
      <section className="top_section">
        <span className="storeName">{item.deviceAlias}</span>
        {/* <img src={arrow} className="next_arrow_icon" alt="바로가기" /> */}
      </section>

      <section className="bottom_section">
        <div className="totalPrice_wrapper">
          <span className="title">총 매출</span>
          <span className="totalPrice">{totalSales.toLocaleString('ko-KR')}</span>

          <div className="sub_price_wrapper">
            <div className="price payCard">
              <span className="text">카드</span>
              <span className="money">{item.card.toLocaleString('ko-KR')}</span>
            </div>

            <div className="price payCash">
              <span className="text">현금</span>
              <span className="money">{item.cash.toLocaleString('ko-KR')}</span>
            </div>

            <div className="price payEtc">
              <span className="text">기타</span>
              <span className="money">{item.etc.toLocaleString('ko-KR')}</span>
            </div>
          </div>
        </div>

        <div className="past_price_wrapper">
          <div className="past">
            <span className="date">{formattedTwoDaysAgo}</span>
            <span className="price">{item.twoDay.toLocaleString('ko-KR')}</span>
          </div>

          <div className="past">
            <span className="date">{formattedYesterday}</span>
            <span className="price">{item.oneDay.toLocaleString('ko-KR')}</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PerMachineItem;
