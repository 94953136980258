import { atom } from 'recoil';
import { ErrorOrYesModalProps } from './types';
import { imgTypes } from '@dataTypes/imgTypes';

const errorOrYesModalState = atom<ErrorOrYesModalProps>({
  key: 'errorOrYesModal',
  default: { status: false, imgType: imgTypes.ERROR, title: '', text: '', modalBG: true },
});

export default errorOrYesModalState;
