import React, { useState } from 'react';
import { Button, Modal, Nav } from 'react-bootstrap';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import {
  getSearchStoreUser,
  grantUserListData,
  grantUserRequestData,
} from '@dataTypes/grantAuthority';
import { grantUser, searchStoreUserList } from '@apis/grantAuthority';
import { GRANTAUTHORITY } from './queryKey/queryKey';
import { AUTH } from '@dataTypes/authTypes';
import { useParams } from 'react-router-dom';
import { AxiosHeaders } from 'axios';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';
import storeInfo from '../../recoil/storeInfo/atom';

interface ShowConfirmMemberModalProps {
  showConfirmModal: boolean;
  onHide: () => void;
  user: getSearchStoreUser | undefined;
  closeAddMemberModal: () => void;
}

const ShowConfirmMemberModal = ({
  showConfirmModal,
  onHide,
  user,
  closeAddMemberModal,
}: ShowConfirmMemberModalProps) => {
  const currentStoreInfo = useRecoilValue(storeInfo);
  const queryClient = useQueryClient();
  const [navActive, setNavActive] = useState({
    manager: true,
    user: false,
  });
  const setErrorState = useSetRecoilState(errorOrYesModalState);
  const { mutate: grantUserMutate } = useMutation((data: grantUserRequestData) => grantUser(data), {
    onSuccess: () => {
      onHide();
      closeAddMemberModal();
      queryClient.invalidateQueries([GRANTAUTHORITY.GRANTAUTHORITYLISTS]);
    },
    onError: (error: AxiosHeaders) => {
      /*
        검색된 사용자가 없을 때: 400.
        이미 지점에 권한이 있을 때: 400.
      */
      if (error.response.status === 400) {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: '요청이 거부되었습니다.',
          text: '이미 권한이 있는 지점이거나, <br /> 검색된 사용자 오류입니다.',
          modalBG: true,
        });
      } else if (error.response.status === 401) {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: '인증되지 않은 사용자입니다.',
          text: '로그인 정보가 유효하지 않거나, <br /> 로그인 유효 시간이 경과하였습니다.',
          modalBG: true,
        });
      } else if (error.response.status === 403) {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: '권한이 없어 요청이 거부되었습니다.',
          text: '관리자에게 권한을 요청하거나, <br /> 해당 기능의 권한 여부를 확인해주세요.',
          modalBG: true,
        });
      } else {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: '알 수 없는 오류입니다.',
          text: '로그아웃 후 재시도 해주세요. <br /> 해당 오류가 계속되면 관리자에게 문의해주세요.',
          modalBG: true,
        });
      }
    },
  });

  const navActiveHandler = (nav: string) => {
    setNavActive((prevNavActive) => ({
      ...prevNavActive,
      [nav]: true,
      manager: nav === 'manager',
      user: nav === 'user',
    }));
  };

  const confirmGrantUserHandler = () => {
    if (currentStoreInfo && currentStoreInfo.currentStoreId) {
      let activeNavAuth: AUTH = AUTH.USER;
      if (navActive.manager) {
        activeNavAuth = AUTH.MANAGER;
      } else {
        activeNavAuth = AUTH.USER;
      }
      const data = {
        userId: user?.id,
        storeId: +currentStoreInfo.currentStoreId,
        authority: activeNavAuth,
      };
      grantUserMutate(data);
    } else {
      console.error('currentStoreInfo error');
    }
  };

  return (
    <Modal
      show={showConfirmModal}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      centered={true}
      id={'show_member_detail'}
      className="detail_member"
    >
      <Modal.Header closeButton className="logout_header_wrapper">
        <Modal.Title className="cart_header_text">권한 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body className="member_modal_body">
        <div className="member_text">
          <div className="text">
            <span>이름: </span>
            <span className="strong">{user?.name}</span>
          </div>
          <div className="text">
            <span>휴대폰 번호: </span>
            <span className="strong">{user?.phoneNumber}</span>
          </div>
          <div className="member_type_btn_wrapper">
            <Nav fill variant="tabs" defaultActiveKey={'link-1'} className="add_nav_section">
              <Nav.Item
                onClick={() => navActiveHandler('manager')}
                className="nav_custom_item left"
              >
                <Nav.Link eventKey="link-1" className={`nav_left_section`}>
                  매니저
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => navActiveHandler('user')} className="nav_custom_item right">
                <Nav.Link eventKey="link-2" className={`nav_right_section`}>
                  유저
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="member_footer_wrapper">
        <Button
          className="member_btn confirm"
          variant="secondary"
          onClick={confirmGrantUserHandler}
        >
          적용
        </Button>
        <Button className="member_btn cancel" variant="secondary" onClick={onHide}>
          취소
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowConfirmMemberModal;
