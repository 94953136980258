import React, { useEffect } from 'react';
import logo from '@assets/logo/smartLab_logo(black_horizontal).png';
import { useNavigate } from 'react-router-dom';

const MainLoading = () => {
  const navigation = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('go navigation');
      navigation('signin');
    }, 1000);

    // 클린업 함수
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="main_loading_container">
      <img className="logo" src={logo} alt="로고" />
    </div>
  );
};

export default MainLoading;
