import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const FindThenChangePassword = () => {
  const [inputValue, setInputValue] = useState({ newPassword: '', checkNewPassword: '' });
  const [inputError, setInputError] = useState({
    pw: false,
    checkPw: false,
  });

  const passwordInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, '').slice(0, 30);
    setInputValue((prevState) => ({
      ...prevState,
      newPassword: sanitizedValue,
    }));
  };

  const pwBlurHandler = () => {
    const regex = /^[^\s]{8,30}$/;
    setInputError((prevState) => ({
      ...prevState,
      pw: regex.test(inputValue.newPassword) ? false : true,
    }));
  };

  const pwCheckBlurHandler = () => {
    setInputError((prevState) => ({
      ...prevState,
      checkPw: inputValue.newPassword === inputValue.checkNewPassword ? false : true,
    }));
  };

  const passwordCheckInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, '').slice(0, 30);
    setInputValue((prevState) => ({
      ...prevState,
      checkNewPassword: sanitizedValue,
    }));
  };

  return (
    <div>
      <div className="input_wrapper">
        <Form.Control
          value={inputValue.newPassword}
          type="password"
          id="password"
          autoComplete="on"
          maxLength={30}
          className="input_section"
          placeholder="비밀번호"
          onBlur={pwBlurHandler}
          onChange={passwordInputHandler}
        />
      </div>

      <div className="input_wrapper mt-2">
        <Form.Control
          value={inputValue.checkNewPassword}
          type="password"
          id="password_check"
          maxLength={30}
          autoComplete="off"
          className="input_section"
          placeholder="비밀번호 확인"
          onBlur={pwCheckBlurHandler}
          onChange={passwordCheckInputHandler}
        />
      </div>

      {inputError.pw && <div className="id_error">8자 ~ 30자까지 비밀번호를 입력해주세요.</div>}
      {!inputError.pw && inputError.checkPw && (
        <div className="id_error">비밀번호가 일치하지 않습니다. 다시 확인해주세요.</div>
      )}

      <ul>
        <li>8~30자 까지의 영문 대/소문자, 숫자, 특수기호를 포함하여 사용할 수 있습니다.</li>
        <li>
          생년월일, 전화번호 등 개인정보와 관련된 숫자, 연속된 키보드배열과 같이 쉬운 비밀번호는
          타인이 쉽게 알아낼 수 있으니 사용을 자제해주세요.
        </li>
      </ul>

      <Button>변경하기</Button>
    </div>
  );
};
4;
export default FindThenChangePassword;
