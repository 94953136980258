import HejHome from '@components/internetOfThings/InternetOfThingsMain';
import React from 'react';
import CommonHeader from '@components/common/CommonHeader';

const InternetOfThings = () => {
  return (
    <div id="iot_container">
      <CommonHeader backBtnUrl="/admin/dashboard">IOT</CommonHeader>
      <HejHome />
    </div>
  );
};

export default InternetOfThings;
