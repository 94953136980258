import CommonHeader from '@components/common/CommonHeader';
import FindPasswordContainer from '@components/FindPassword/FindPasswordContainer';
import React from 'react';

const FindPassword = () => {
  return (
    <div id="find_password_container">
      <CommonHeader>비밀번호 찾기</CommonHeader>
      <FindPasswordContainer />
    </div>
  );
};

export default FindPassword;
