import React, { useState } from 'react';
import logo from '@assets/logo/smartLab_logo(black_horizontal).png';
import { Button, Form } from 'react-bootstrap';
import { deleteAccountRequest } from '@apis/deleteAccount';
import { useSetRecoilState } from 'recoil';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosHeaders } from 'axios';
import storeInfo from '../../recoil/storeInfo/atom';

const DeleteAccountMain = () => {
  const [inputValue, setInputValue] = useState('');
  const [check, setCheck] = useState(false);
  const setEndState = useSetRecoilState(errorOrYesModalState);
  const setCurrentStoreInfo = useSetRecoilState(storeInfo);
  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };
  const navigation = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteAccountAsync } = useMutation(() => deleteAccountRequest(inputValue), {
    onSuccess: (data) => {
      if (data) {
        setEndState({
          status: true,
          imgType: imgTypes.ERROR,
          title: '탈퇴 처리가 완료되었습니다.',
          text: '이용해주셔서 감사합니다. <br /> 확인을 눌러주시면 로그인 페이지로 이동합니다.',
          modalBG: true,
          yesCallBack: goHomeHandler,
        });

        setCurrentStoreInfo({
          currentStore: '',
          currentAuthority: '',
          currentStoreId: undefined,
          storeLists: [],
        });

        // queryClient.invalidateQueries();
      }
    },
    onError: (data: AxiosHeaders) => {
      setEndState({
        status: true,
        imgType: imgTypes.ERROR,
        title: data.response.data.message,
        text: '비밀번호 또는 로그인 유지 확인부탁드립니다',
        modalBG: true,
      });
    },
  });

  const deleteAccountRequestHandler = async () => {
    if (check) {
      deleteAccountAsync();
    } else {
      setEndState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '필수 사항이 체크되지 않았습니다',
        text: '"위 내용을 모두 확인했습니다." <br /> 체크버튼을 클릭한 후 탈퇴가 가능합니다.',
        modalBG: true,
      });
    }
  };

  const goHomeHandler = () => {
    setTimeout(() => {
      navigation('/');
    }, 300);
  };

  return (
    <div className="delete_account_main">
      <img src={logo} className="logo" alt="로고" />
      <div className="alert_text">스마트랩 탈퇴 전 꼭 확인해주세요</div>
      <div className="info_wrapper">
        <div>탈퇴하시면 현재 사용 중이신 계정을 더 이상 사용할 수 없게 됩니다.</div>
        <div>탈퇴하시면 데이터 복구가 불가능하며 관리자에게 부여받은 모든 권한이 해제됩니다.</div>
        <div>한 번 삭제된 계정은 이전 상태로 복구할 수 없습니다.</div>
      </div>

      <div className="check_wrapper">
        <Form.Check
          checked={check}
          type={'checkbox'}
          id={`check_id_type_3`}
          label={`위 내용을 모두 확인했습니다.`}
          onChange={() => setCheck((prev) => !prev)}
        />
      </div>

      <div className="input_form">
        <div className="sign_up_title">현재 비밀번호 입력</div>
        <div className="input_wrapper">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              value={inputValue}
              type="password"
              id="delete_pw"
              maxLength={25}
              className="input_section"
              placeholder="비밀번호"
              onChange={inputHandler}
              autoComplete="off"
            />
          </Form>
        </div>
      </div>
      <Button className="confirm_btn" onClick={deleteAccountRequestHandler}>
        탈퇴하기
      </Button>
    </div>
  );
};

export default DeleteAccountMain;
