import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';
import SelectDateWithSalesContainer from '@components/totalSales/SelectDateWithSalesContainer';
import React from 'react';

const TotalSales = () => {
  return (
    <div id="total_sales_container">
      <DashBoardHeader />
      <SelectDateWithSalesContainer />
    </div>
  );
};

export default TotalSales;
