import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { checkSMS, postDuplication, postEnroll, sendSMS } from '@apis/signUp';
import { IoMdArrowBack, IoMdClose } from 'react-icons/io';
import EndIdCheck from './EndIdCheck';
import { ISignUpRequest } from '@dataTypes/signTypes';
import axios, { AxiosHeaders } from 'axios';
import { imgTypes } from '@dataTypes/imgTypes';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import SignUpCheckRadio from './SignUpCheckRadio';
import CommonHeader from '@components/common/CommonHeader';
const SignUpUser = () => {
  const navigation = useNavigate();
  const setErrorState = useSetRecoilState(errorOrYesModalState);
  const [inputValue, setInputValue] = useState({
    id: '',
    pw: '',
    checkPw: '',
    name: '',
    phone: '',
    authCode: '',
  });
  const [showAuthCode, setShowAuthCode] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);

  /*
    error: all false, idCheck: true 회원가입 가능
  */
  const [inputError, setInputError] = useState({
    korean: false,
    id: false,
    pw: false,
    checkPw: false,
    name: false,
    phone: false,
  });
  const [agreeResult, setAgreeResult] = useState({ one: true, two: true, three: true });
  const [idCheck, setIdCheck] = useState<null | boolean>(null);

  const { mutateAsync: postEnrollAsync } = useMutation((data: ISignUpRequest) => postEnroll(data), {
    onError: (error: AxiosHeaders) => {
      if (error.response.status === 400) {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: error.response.data.message,
          modalBG: true,
        });
      }
    },
  });

  const { mutateAsync: sendSMSAsync } = useMutation((data: string) => sendSMS(data), {
    onError: (error: AxiosHeaders) => {
      if (error.response.status === 400) {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: error.response.data.message,
          modalBG: true,
        });
      }
    },
  });

  const { mutateAsync: checkSMSAsync } = useMutation(
    ({ authCode, phoneNumber }: { authCode: string; phoneNumber: string }) =>
      checkSMS(authCode, phoneNumber),
    {
      onError: (error: AxiosHeaders) => {
        if (error.response.status === 400) {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: error.response.data.message,
            modalBG: true,
          });
        } else if (error.response.status === 409) {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: error.response.data.message,
            text: '2분 후 다시 시도해주세요.',
            modalBG: true,
          });
        }
      },
    },
  );

  const idInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdCheck(null);
    const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    const value = event.target.value;
    if (koreanRegex.test(value)) {
      setInputError((prevState) => ({
        ...prevState,
        korean: true,
      }));
    } else {
      setInputError((prevState) => ({
        ...prevState,
        korean: false,
      }));
    }

    // 특수 문자 제외하고, 공백도 제외한 값으로 처리
    const sanitizedValue = value.replace(/[^a-zA-Z0-9-_]/g, '').slice(0, 25);
    setInputValue((prevState) => ({
      ...prevState,
      id: sanitizedValue,
    }));
  };

  const idBlurHandler = () => {
    const regex = /^[a-zA-Z0-9-]{5,25}$/;
    setInputError((prevState) => ({
      ...prevState,
      id: regex.test(inputValue.id) ? false : true,
    }));
  };

  const passwordInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, '').slice(0, 30);
    setInputValue((prevState) => ({
      ...prevState,
      pw: sanitizedValue,
    }));
  };

  const pwBlurHandler = () => {
    const regex = /^[^\s]{8,30}$/;
    setInputError((prevState) => ({
      ...prevState,
      pw: regex.test(inputValue.pw) ? false : true,
    }));
  };

  const passwordCheckInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, '').slice(0, 30);
    const regex = /^[^\s]{8,30}$/;
    setInputValue((prevState) => ({
      ...prevState,
      checkPw: sanitizedValue,
    }));
  };

  const pwCheckBlurHandler = () => {
    setInputError((prevState) => ({
      ...prevState,
      checkPw: inputValue.pw === inputValue.checkPw ? false : true,
    }));
  };

  const nameInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, '').slice(0, 25);
    setInputValue((prevState) => ({
      ...prevState,
      name: sanitizedValue,
    }));
  };

  const nameBlurHandler = () => {
    const regex = /^[^\s]{2,25}$/;
    setInputError((prevState) => ({
      ...prevState,
      name: regex.test(inputValue.name) ? false : true,
    }));
  };

  const phoneInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 11);
    setInputValue((prevState) => ({
      ...prevState,
      phone: sanitizedValue,
    }));
  };

  const checkSMSInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
    setInputValue((prevState) => ({
      ...prevState,
      authCode: sanitizedValue,
    }));
  };

  const checkDuplicationHandler = () => {
    const regex = /^[^\s]{5,25}$/;
    if (!regex.test(inputValue.id)) {
      return;
    }

    postDuplication(inputValue.id).then((result) => setIdCheck(result ? true : false));
  };

  const submitEnrollHandler = async () => {
    /*
    inputError: all false, idCheck: true 회원가입 가능
  */
    if (
      idCheck &&
      Object.values(inputError).every((value) => value === false) &&
      agreeResult.one &&
      agreeResult.two
    ) {
      //1. 인증번호 체크
      const checkSMSResult = await checkSMSAsync({
        authCode: inputValue.authCode,
        phoneNumber: inputValue.phone,
      });

      if (checkSMSResult) {
        //2. 회원가입
        const response = await postEnrollAsync({
          loginId: inputValue.id,
          password: inputValue.pw,
          name: inputValue.name,
          phoneNumber: inputValue.phone,
          authCode: inputValue.authCode,
        });

        if (response.signup) {
          /*
          회원가입 완료 시 storeAccess에 따라서 등록되어 있는 지점이랑 같은 전화번호면 자동으로 로그인 되게
        */
          if (response.storeAccess) {
            navigation(`/endsignup/${response.storeAccess}/${response.storeCnt}`);
          } else {
            navigation(`/endsignup`);
          }
        } else {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '서버 오류입니다.',
            text: '잠시후 다시 시도해주세요.',
            modalBG: true,
          });
        }
      } else {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: '인증번호가 일치하지 않습니다.',
          text: '새로고침 후 재시도해주세요.',
          modalBG: true,
        });
      }
    } else {
      setErrorState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '필수 항목이 입력 또는 선택되지 않았습니다.',
        text: '아이디 중복체크, 비밀번호, 이름, 휴대폰 번호, <br />약관동의를 전부 입력 또는 선택해주셔야 합니다.',
        modalBG: true,
      });
    }
  };

  const sendSMSHandler = async () => {
    const regex = /^\d{11}$/;

    if (!regex.test(inputValue.phone)) {
      setErrorState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '휴대폰 번호 11자리를 입력해주세요',
        modalBG: true,
      });

      return;
    }
    const response = await sendSMSAsync(inputValue.phone);

    if (response) {
      setErrorState({
        status: true,
        imgType: imgTypes.YES,
        title: '인증번호가 전송되었습니다',
        text: '인증번호 6자리를 입력해주세요.',
        modalBG: true,
      });

      setShowAuthCode(true);
      setIsTimerActive(true);
      setTimer(120);
    }
  };

  useEffect(() => {
    let countdown: any;

    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
      setIsTimerActive(false); // Stop the timer when it reaches 0
    }

    return () => clearInterval(countdown); // Cleanup the interval
  }, [isTimerActive, timer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className="user_sign_up_container">
      <CommonHeader>회원가입</CommonHeader>
      <Form className="form_wrapper">
        <Form.Group className="mb-2">
          <div className="input_form">
            <div className="sign_up_title">아이디</div>
            <div className="input_wrapper with_flex">
              <Form.Control
                value={inputValue.id}
                type="text"
                id="input_id"
                maxLength={25}
                className="input_section"
                placeholder="아이디(한글 제외 25자리까지)"
                autoComplete="on"
                onBlur={idBlurHandler}
                onChange={idInputHandler}
              />

              <Button className="check_id_btn" onClick={checkDuplicationHandler}>
                {idCheck ? <EndIdCheck /> : <div>중복확인</div>}
              </Button>
            </div>

            {inputError.id && <div className="id_error">5자 ~ 25자까지 아이디를 입력해주세요.</div>}
            {inputError.korean && <div className="id_error">한글은 입력하실 수 없습니다.</div>}
            {!inputError.id && idCheck === false && (
              <div className="id_error">중복된 아이디입니다.</div>
            )}
          </div>

          <div className="input_form">
            <div className="sign_up_title">비밀번호</div>
            <div className="input_wrapper">
              <Form.Control
                value={inputValue.pw}
                type="password"
                id="password"
                autoComplete="on"
                maxLength={30}
                className="input_section"
                placeholder="비밀번호"
                onBlur={pwBlurHandler}
                onChange={passwordInputHandler}
              />
            </div>

            <div className="input_wrapper mt-2">
              <Form.Control
                value={inputValue.checkPw}
                type="password"
                id="password_check"
                maxLength={30}
                autoComplete="off"
                className="input_section"
                placeholder="비밀번호 확인"
                onBlur={pwCheckBlurHandler}
                onChange={passwordCheckInputHandler}
              />
            </div>

            {inputError.pw && (
              <div className="id_error">8자 ~ 30자까지 비밀번호를 입력해주세요.</div>
            )}
            {!inputError.pw && inputError.checkPw && (
              <div className="id_error">비밀번호가 일치하지 않습니다. 다시 확인해주세요.</div>
            )}
          </div>

          <div className="input_form">
            <div className="sign_up_title">이름</div>
            <div className="input_wrapper">
              <Form.Control
                value={inputValue.name}
                type="text"
                id="name"
                maxLength={25}
                className="input_section"
                placeholder="이름"
                onBlur={nameBlurHandler}
                onChange={nameInputHandler}
              />
            </div>

            {inputError.name && <div className="id_error">2자 ~ 25자까지 이름을 입력해주세요.</div>}
          </div>

          <div className="input_form">
            <div className="sign_up_title">휴대폰 번호</div>
            <div className="input_wrapper with_flex">
              <Form.Control
                value={inputValue.phone}
                type="text"
                id="phone_number"
                maxLength={11}
                className="input_section"
                placeholder="휴대폰 번호"
                onChange={phoneInputHandler}
              />
              {/* <Button className="check_id_btn" onClick={sendSMSHandler} >
                인증하기
              </Button> */}
              <Button className="check_id_btn" onClick={sendSMSHandler} disabled={showAuthCode}>
                {!showAuthCode ? '인증하기' : '전송 완료'}
              </Button>
            </div>
          </div>
          {showAuthCode && (
            <div className="auth_code_wrapper">
              <div className="input_wrapper">
                <Form.Control
                  value={inputValue.authCode}
                  type="text"
                  id="check_form"
                  maxLength={6}
                  className="input_section"
                  placeholder="인증 번호 6자리를 입력해주세요."
                  onChange={checkSMSInputHandler}
                />
              </div>
              <div className="time">{formatTime(timer)}</div>
            </div>
          )}
        </Form.Group>
        <SignUpCheckRadio agreeResult={agreeResult} setAgreeResult={setAgreeResult} />
      </Form>

      <Button className="submit_enroll_btn" onClick={submitEnrollHandler}>
        가입하기
      </Button>
    </div>
  );
};

export default SignUpUser;
