import { useQuery } from '@tanstack/react-query';
import { getStoreUserListsByStoreID } from '@apis/grantAuthority';
import { grantUserListData } from '@dataTypes/grantAuthority';
import { GRANTAUTHORITY } from '@components/grantAuthority/queryKey/queryKey';
import { useEffect, useState } from 'react';

export const useGetGrantListAuthorityByStoreID = (storeId: number | undefined) => {
  const [userLists, setUserLists] = useState<grantUserListData[]>([]);

  const {
    data: getGrantListAuthorityByStoreIDData,
    isLoading: getGrantListAuthorityByStoreIDLoading,
    isFetching: getGrantListAuthorityByStoreIDFetching,
  } = useQuery<grantUserListData[]>(
    [GRANTAUTHORITY.GRANTAUTHORITYLISTS, storeId],
    () => getStoreUserListsByStoreID(storeId),
    {
      enabled: !!storeId,
    },
  );

  useEffect(() => {
    if (getGrantListAuthorityByStoreIDData) {
      setUserLists(getGrantListAuthorityByStoreIDData);
    }
  }, [getGrantListAuthorityByStoreIDData]);

  return {
    userLists,
    getGrantListAuthorityByStoreIDData,
    getGrantListAuthorityByStoreIDLoading,
    getGrantListAuthorityByStoreIDFetching,
  };
};
