import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import ErrorPage from '../pages/error/ErrorPage';
import SalesDashboard from '@pages/salesDashboard/SalesDashboard';
import MainLoading from '@pages/MainLoading';
import SignIn from '@pages/sign/SignIn';
import TotalSales from '@pages/totalSales/TotalSales';
import PerMachine from '@pages/perMachine/PerMachine';
import ChargeBoard from '@pages/chargeBoard/ChargeBoard';
import EndSignUp from '@pages/sign/EndSignUp';
import GrantAuthority from '@pages/grantAuthority/GrantAuthority';
import SignUp from '@pages/sign/SignUp';
import DeleteAccount from '@pages/sign/DeleteAccount';
import MyPage from '@pages/sign/MyPage';
import InternetOfThings from '@pages/internetOfThings/InternetOfThings';
import CCTVPage from '@pages/cctv/CCTVPage';
import ChangePassword from '@pages/sign/ChangePassword';
import AddStore from '@pages/addStore/AddStore';
import FindPassword from '@pages/sign/FindPassword';

interface IRouter {
  path: string;
  element: React.ReactNode;
  errorElement?: React.ReactNode;
  children?: IRouter[];
}

const routerData: IRouter[] = [
  // 관리자 페이지 /admin/**
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <MainLoading />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
      {
        path: '/endsignup/:storeAccess?/:storeCnt?',
        element: <EndSignUp />,
      },
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/admin/mypage',
        element: <MyPage />,
      },
      {
        path: '/admin/deleteaccount',
        element: <DeleteAccount />,
      },
      {
        path: '/admin/changepassword',
        element: <ChangePassword />,
      },
      {
        path: '/admin/dashboard',
        element: <SalesDashboard />,
      },
      {
        path: '/admin/totalsales',
        element: <TotalSales />,
      },
      {
        path: '/admin/permachine',
        element: <PerMachine />,
      },
      {
        path: '/admin/charge',
        element: <ChargeBoard />,
      },
      {
        path: '/admin/grant',
        element: <GrantAuthority />,
      },
      {
        path: '/admin/hejHome',
        element: <InternetOfThings />,
      },
      {
        path: '/admin/cctv',
        element: <CCTVPage />,
      },
      { path: '/admin/addstore', element: <AddStore /> },
      {
        path: '/findpassword',
        element: <FindPassword />,
      },
      {
        path: '/error',
        element: <ErrorPage />,
      },
    ],
  },
];

export const router = createBrowserRouter(routerData);
