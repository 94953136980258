import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { useQuery } from '@tanstack/react-query';
import { ICheckIOTResponse, IDevice, ITemperature } from '@dataTypes/hejHomeTypes';
import { CHECKIOT } from '@components/internetOfThings/queryKey/queryKey';
import { getCheckIOT } from '@apis/hejHome';
import { useEffect, useState } from 'react';
import { getDeviceList, getTemperature } from '../../hejHomeApis/deviceList';
import { instance } from '../../hejHomeApis/index';

// 쿼리 키를 사용해 디바이스 리스트와 온도 데이터 쿼리 관리
export const useHejHomeList = () => {
  console.log(
    '`${process.env.REACT_APP_HEJHOME_API_URL}`: ',
    `${process.env.REACT_APP_HEJHOME_API_URL}`,
  );

  const storeInfoState = useRecoilValue(storeInfo);
  const [deviceList, setDeviceList] = useState<IDevice[]>([]);

  // 액세스 토큰을 업데이트하는 함수
  const updateToken = (token: string) => {
    instance.defaults.headers['Authorization'] = `Bearer ${token}`;
  };

  // IOT 체크 쿼리
  const { data: checkIOTResponseData, refetch: checkIOTRefetch } = useQuery<ICheckIOTResponse>(
    [CHECKIOT.USERCHECKIOT, storeInfoState],
    () => getCheckIOT(),
    {
      onSuccess: (data) => {
        if (data?.hasAccessToken) {
          updateToken(data.accessToken); // Axios 인스턴스의 헤더 업데이트
        }
      },
    },
  );

  // 디바이스 리스트 쿼리
  const { data: deviceListData, error: deviceListError } = useQuery<IDevice[]>(
    [CHECKIOT.DEVICELIST, storeInfoState],
    () => getDeviceList(),
    {
      enabled: !!checkIOTResponseData?.hasAccessToken, // 토큰이 있을 때만 호출
      onSuccess: (data) => setDeviceList(data),
      onError: (error) => console.error('Error fetching device list:', error),
    },
  );

  console.log('deviceList: ', deviceList);

  // 온도 데이터 쿼리, deviceList가 업데이트 될 때마다 호출
  const temperatureDevice =
    (deviceList.length > 0 &&
      deviceList.find((device) => device.deviceType.includes('SensorTh'))) ||
    undefined;

  const { data: temperatureData, error: temperatureError } = useQuery<ITemperature | undefined>(
    [CHECKIOT.TEMPERATURE, temperatureDevice?.id],
    () => (temperatureDevice ? getTemperature(temperatureDevice.id) : Promise.resolve(undefined)),
    {
      enabled: !!temperatureDevice?.id, // deviceId가 있을 때만 호출
    },
  );

  return {
    checkIOTResponseData,
    deviceList,
    temperatureData,
    deviceListError,
    temperatureError,
    checkIOTRefetch,
  };
};
