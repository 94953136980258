import {
  IAirconditionerState,
  IControlPlugAirconditionerRequest,
  IDevice,
} from '@dataTypes/hejHomeTypes';
import mainIcon from '@assets/icon/airconditioner_main_icon.png';
import onOffIconBlack from '@assets/icon/on_off_icon(black).png';
import onOffIconWhite from '@assets/icon/on_off_icon(white).png';
import React, { useEffect, useState } from 'react';
import { stateDevice, controlDevice } from '../../hejHomeApis/deviceList';
import AirconditionerControlModal from './AirconditionerControlModal';
import { Button } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa6';
import { instance } from '../../hejHomeApis/index';
import onOffIconColor from '@assets/icon/on_off_icon(color).png';

interface IDeviceProps {
  list: IDevice;
  currentTemperature: string;
  currentHumidity: string;
}

const Airconditioner = ({ list, currentHumidity, currentTemperature }: IDeviceProps) => {
  const accessToken = instance.defaults.headers['Authorization'];
  const [airconditionerState, setAirconditionerState] = useState<IAirconditionerState>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    stateDevice(list.id).then((list: any) => setAirconditionerState(list));
  }, [list.id]);

  const formattedMode = (mode: number | undefined) => {
    if (mode !== undefined) {
      switch (mode) {
        case 0:
          return '냉방';
        case 1:
          return '난방';
        case 2:
          return '자동';
        case 3:
          return '송풍';
        case 4:
          return '제습';
        default:
          return '알 수 없음';
      }
    }
    return '알 수 없음';
  };

  const formattedPanSpeed = (speed: number | undefined) => {
    if (speed !== undefined) {
      switch (speed) {
        case 0:
          return '자동';
        case 1:
          return '1단';
        case 2:
          return '2단';
        case 3:
          return '3단';
        default:
          return '알 수 없음';
      }
    }
    return '알 수 없음';
  };

  const onOffHandler = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!list.online) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (airconditionerState && airconditionerState.id) {
      const requirments: IControlPlugAirconditionerRequest = {
        power: airconditionerState.deviceState.power === '켜짐' ? false : true,
      };
      try {
        //openApi
        const result = await controlDevice(airconditionerState.id, requirments);
        if (result.status === 200) {
          setAirconditionerState((prevState) => {
            if (!prevState) return prevState; // 상태가 undefined일 경우 처리
            return {
              ...prevState,
              deviceState: {
                ...prevState.deviceState,
                power: airconditionerState.deviceState.power === '켜짐' ? '꺼짐' : '켜짐',
              },
            };
          });
        } else {
          console.error('Request failed with status: ', result.status);
        }
      } catch (error) {
        console.error('Error in controlDevice: ', error);
      }
    }
  };

  return (
    <>
      <li className="device_list" onClick={() => setShow(true)}>
        <div className="top_wrapper">
          <img className="main_icon" alt="온습도센서 아이콘" src={mainIcon} />
          <div className="title">{list.name}</div>

          <div className="go_arrow">
            <FaChevronRight />
          </div>
        </div>

        <div className="bottom_wrapper">
          <div className="current_state_wrapper">
            <span>{airconditionerState?.deviceState.temperature}°C</span>
            <div className="custom_border_right"></div>
            <span>{formattedMode(airconditionerState?.deviceState.mode)}</span>
            <div className="custom_border_right"></div>
            <span>{formattedPanSpeed(airconditionerState?.deviceState.fanSpeed)}</span>
          </div>
          <div
            className={`on_off_btn_wrapper ${airconditionerState?.deviceState.power === '켜짐' && list.online ? 'on' : 'off'}`}
            onClick={onOffHandler}
          >
            <img
              className="button_icon"
              src={
                airconditionerState?.deviceState.power === '켜짐' && list.online
                  ? onOffIconBlack
                  : onOffIconBlack
              }
              alt="전원 버튼"
            />
            <span>전원</span>
          </div>
        </div>
      </li>

      <AirconditionerControlModal
        airconditionerState={airconditionerState}
        setAirconditionerState={setAirconditionerState}
        currentHumidity={currentHumidity}
        currentTemperature={currentTemperature}
        show={show}
        closeModal={() => setShow(false)}
        name={list.name}
        deviceOnline={list.online}
      />
    </>
  );
};

export default Airconditioner;
