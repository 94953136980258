import { getDashboardMonthSalesList } from '@apis/salesDashboard';
import { SALESDASHBOARD } from '@components/salesDashBoard/queryKey/queryKey';
import {
  IActiveTotalList,
  IDashboardSummary,
  ISummaryDayItem,
  IThisMonthData,
} from '@dataTypes/dashBoardSummary';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format, subDays } from 'date-fns';
import { useEffect, useState } from 'react';

export const useDashBoardSummaryList = (storeId: number | undefined, date: string) => {
  const [getDashBoardListsLoading, setGetDashBoardListsLoading] = useState(false);
  const [salesData, setSalesData] = useState<ISummaryDayItem[]>([]); //달력 목록
  const [thisMonthData, setThisMonthData] = useState<IThisMonthData>(); //이번 달 매출 요약
  const [activeTotalList, setActiveTotalList] = useState<IActiveTotalList>(); //활성화 된 달 매출 요약
  const [hasServerError, setHasServerError] = useState(false);

  const {
    data: getSummaryData,
    isLoading,
    isFetching,
  } = useQuery<IDashboardSummary>(
    [SALESDASHBOARD.DASHBOARDSUMMARY, storeId, date],
    () => getDashboardMonthSalesList(storeId, date),
    {
      enabled: !!storeId,
      cacheTime: 180000,
      staleTime: 180000,
      onError: (err) => {
        if (axios.isAxiosError(err) && err.response?.status === 500) {
          setHasServerError(true);
        }
      },
    },
  );

  useEffect(() => {
    setThisMonthData({
      todaySales: 0,
      yesterdaySales: 0,
      totalMonthlySales: 0,
      lastMonthlySales: 0,
    });
  }, [storeId]);

  useEffect(() => {
    if (getSummaryData && getSummaryData?.list) {
      //1. 달력에 표기할 해당 월의 매출
      setSalesData(getSummaryData.list);

      //2. 활성화된 월의 매출
      if (getSummaryData.list.length > 0) {
        setActiveTotalList({
          totalMonthlySales: getSummaryData.totalMonthlySales,
          monthlySalesByCard: getSummaryData.monthlySalesByCard,
          monthlySalesByCash: getSummaryData.monthlySalesByCash,
          monthlySalesByEtc: getSummaryData.monthlySalesByEtc,
        });
      }

      //3. 최상단 대쉬보드 요약 데이터 매출
      const currentMonth = format(new Date(), 'yyyy-MM');

      const filteredData = getSummaryData.list.filter(
        (item) => item.date.slice(0, 7) === currentMonth,
      );

      if (filteredData && filteredData.length > 0) {
        const today = format(new Date(), 'yyyy-MM-dd');
        const filteredToday = filteredData.find((list) => list.date === today);
        const todaySales = filteredToday
          ? filteredToday.card + filteredToday.cash + filteredToday.etc
          : 0;

        const yesterday = format(subDays(new Date(), 1), 'yyyy-MM-dd');
        const filteredYesterday = filteredData.find((list) => list.date === yesterday);

        const yesterdaySales = filteredYesterday
          ? filteredYesterday.card + filteredYesterday.cash + filteredYesterday.etc
          : 0;

        //0인 경우 오늘 데이터가 없는거
        setThisMonthData({
          todaySales: todaySales,
          yesterdaySales: getSummaryData.yesterdaySales,
          totalMonthlySales: getSummaryData.totalMonthlySales,
          lastMonthlySales: getSummaryData.lastMonthlySales,
        });
      }
    } else {
      setSalesData([]);

      setActiveTotalList({
        totalMonthlySales: 0,
        monthlySalesByCard: 0,
        monthlySalesByCash: 0,
        monthlySalesByEtc: 0,
      });
    }
  }, [getSummaryData]);

  useEffect(() => {
    setGetDashBoardListsLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  if (hasServerError) {
    return { serverError: true };
  }

  return {
    serverError: false,
    salesData,
    thisMonthData,
    activeTotalList,
    getDashBoardListsLoading,
  };
};
