import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ChargeItem from './ChargeItem';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ChargeInfo from './ChargeInfo';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import BeatLoader from 'react-spinners/BeatLoader';
import { useChargeBoardLists } from '../../hooks/chargeBoard/useChargeBoardLists';
import ChargeInfoSkeleton from './ChargeInfoSkeleton';
import ChargeItemSkeleton from './ChargeItemSkeleton';
import { jsondb } from '../../constants/dbTest';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { ICharge } from '@dataTypes/chargeBoardTypes';

interface SwiperInstance {
  slidePrev: () => void;
  slideNext: () => void;
  updateSlides: () => void;
  slideTo: (index: number, speed?: number, runCallbacks?: boolean) => void;
  activeIndex: number;
  slideReset: () => void;
  update: () => void;
}

const ChargeBoardContainer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const storeInfoValue = useRecoilValue(storeInfo);

  const { getChargeBoardListsData, getChargeBoardListsLoading, setGetChargeBoardListsLoading } =
    useChargeBoardLists(currentYear, storeInfoValue.currentStoreId || 0);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, [storeInfoValue.currentStoreId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setGetChargeBoardListsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const nextBtnHandler = () => {
    if (currentYear === new Date().getFullYear()) {
      return;
    }
    setCurrentYear(currentYear + 1);
    setGetChargeBoardListsLoading(true);

    //테스트 끝나고 지우면됨
    setTimeout(() => {
      setGetChargeBoardListsLoading(false);
    }, 2000);
  };

  const prevBtnHandler = () => {
    setCurrentYear(currentYear - 1);
    setGetChargeBoardListsLoading(true);

    //테스트 끝나고 지우면됨
    setTimeout(() => {
      setGetChargeBoardListsLoading(false);
    }, 2000);
  };

  const fillMissingData = (data: ICharge[] | undefined) => {
    const updatedCurrentYear = currentYear + '00';

    if (data !== undefined) {
      const allMonths = Array.from({ length: 12 }, (_, i) =>
        (Number(updatedCurrentYear) + (i + 1)).toString(),
      );

      // 데이터를 월 기준으로 맵핑 (depositDate를 key로)
      const dataMap: { [key: string]: ICharge } = {};
      data.forEach((item) => {
        dataMap[item.depositDate] = item;
      });

      // 모든 월에 대해 데이터가 없으면 기본값을 추가
      const completeData: ICharge[] = allMonths.map((month) => {
        if (dataMap[month]) {
          return {
            depositDate: month,
            totalCardSales: dataMap[month].totalCardSales || 0,
            totalFees: dataMap[month].totalFees || 0,
            totalCardDepositAmount: dataMap[month].totalCardDepositAmount || 0,
          };
        } else {
          // 데이터가 없으면 기본값을 반환
          return {
            depositDate: month,
          };
        }
      });

      return completeData;
    } else {
      console.log('getChargeBoardListsData is undefined');
    }
  };

  const totalSales = fillMissingData(getChargeBoardListsData)?.reduce((acc, curr) => {
    return acc + (curr.totalCardSales || 0); // totalCardSales가 없을 경우 0으로 처리
  }, 0);

  return (
    <div className="charge_board_wrapper">
      <div className="change_month_wrapper">
        <IoIosArrowBack className="left_arrow" size={'2rem'} onClick={prevBtnHandler} />
        <div>{currentYear}</div>
        <IoIosArrowForward
          className={`right_arrow ${currentYear === new Date().getFullYear() ? 'disable' : ''}`}
          size={'2rem'}
          onClick={currentYear === new Date().getFullYear() ? undefined : nextBtnHandler}
        />
      </div>

      {!getChargeBoardListsLoading ? (
        <>
          <div className="info_wrapper">
            <div className="box_wrapper">
              <div className="card_box box"></div>
              <div>카드</div>
            </div>
            <div className="box_wrapper">
              <div className="cardDepositAmount_box box"></div>
              <div>카드 입금</div>
            </div>
            <div className="box_wrapper">
              <div className="fee_box box"></div>
              <div>수수료</div>
            </div>
          </div>
          <ul className="month_wrapper">
            {getChargeBoardListsData &&
              fillMissingData(getChargeBoardListsData)?.map((list, index) => (
                <ChargeItem
                  key={index}
                  month={list.depositDate}
                  card={list.totalCardSales}
                  fee={list.totalFees}
                  cardDepositAmount={list.totalCardDepositAmount}
                />
              ))}
          </ul>
        </>
      ) : (
        <>
          <ul className="month_wrapper">
            {Array.from({ length: 12 }).map((_, index) => (
              <React.Fragment key={index}>
                <ChargeItemSkeleton />
              </React.Fragment>
            ))}
          </ul>
        </>
      )}

      <ul className="info_list">
        <li>입금 일자는 기존 신용 거래 일자의 +2, 3일 입니다.</li>
        <li>
          현재 BC카드에 대한 입반송 특약 미체결로 BC의 입금결과가 존재하지 않아 카드 매출의 차이가
          발생할 수 있습니다.
        </li>
      </ul>
    </div>
  );
};

export default ChargeBoardContainer;
