import React, { useEffect } from 'react';
import errorIcon from '@assets/img/error_alert_img.png';
import yesIcon from '../../../assets/icon/check_icon.png';
import { Button, Modal } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import errorOrYesModalState from '../../../recoil/errorOrYesModal';
import { ErrorOrYesModalProps } from '../../../recoil/errorOrYesModal/types';
import { imgTypes } from '@dataTypes/imgTypes';

/*
    title: 메인 텍스트
    imgType: 'error' or 'yes'
    text: 부가 설명
    modalBG: 뒷 배경 사용 여부

    setErrorState((prevState) => ({
      ...prevState,
      imgType: imgTypes.ERROR,
      title: '필수정보를 입력하지 않았습니다',
      text: '입력창을 다시 확인해주세요.',
      status: true,
    }));
 */

const ErrorOrYesModal = ({ title, text, modalBG, imgType, yesCallBack }: ErrorOrYesModalProps) => {
  const [errorOrYesModal, setErrorOrYesModal] = useRecoilState(errorOrYesModalState);

  useEffect(() => {
    setErrorOrYesModal((prevState) => ({
      ...prevState,
      status: true,
      imgType: imgType,
      title: title,
      text: text,
      modalBG: modalBG,
      yesCallBack: yesCallBack,
    }));
  }, [modalBG, text, title, imgType, yesCallBack, setErrorOrYesModal]);

  const modalCloseHandler = () => {
    if (yesCallBack) {
      yesCallBack();
    }

    setErrorOrYesModal((prevState) => ({
      ...prevState,
      status: false,
    }));
  };

  const convertStringToJSX = (text: string) => {
    if (text) {
      return text.split('<br />').map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index < text.split('<br />').length - 1 && <br />}
        </React.Fragment>
      ));
    }
    return null;
  };

  return (
    <>
      {errorOrYesModal.status && (
        <>
          <Modal
            show={errorOrYesModal.status}
            onHide={() => modalCloseHandler()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id={'error_modal_container'}
          >
            <Modal.Body>
              <div className="icon_bg">
                {imgType === imgTypes.ERROR && (
                  <img className="error_icon" src={errorIcon} alt="에러 아이콘" />
                )}
              </div>
              <div className="text_wrapper">
                <span className="title">{errorOrYesModal.title}</span>
                {errorOrYesModal.text && (
                  <span className="text">{convertStringToJSX(errorOrYesModal.text)}</span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="close_btn" onClick={modalCloseHandler}>
                확인
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ErrorOrYesModal;
