import React, { ReactNode, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ErrorOrYesModal from '@components/common/errorModal/ErrorOrYesModal';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
interface Props {
  children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const errorState = useRecoilValue(errorOrYesModalState);
  return (
    <>
      <div id="layout">
        {/* <div style={containerStyle}>{children}</div> */}
        {children}
        {errorState.status && (
          <ErrorOrYesModal
            status={errorState.status}
            title={errorState.title}
            text={errorState.text}
            modalBG={true}
            imgType={errorState.imgType}
            yesCallBack={errorState.yesCallBack}
          />
        )}
      </div>
    </>
  );
};

export default Layout;
