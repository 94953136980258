import { getPerMachineTotalSale } from '@apis/perMachine';
import { PERMACHINE } from '@components/perMachine/queryKey/queryKey';
import { perMachineTotalSalesData } from '@dataTypes/perMachineTypes';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useGetMachineTotalSalesList = (storeId: number | undefined, date: string) => {
  const [getPerMachineSaleDataLoading, setGetPerMachineSaleDataLoading] = useState(true);

  const {
    data: getPerMachineSaleData,
    isLoading,
    isFetching,
  } = useQuery<perMachineTotalSalesData[]>(
    [PERMACHINE.PERMACHINESALESLISTS, date, storeId],
    () => getPerMachineTotalSale(storeId, date),
    {
      enabled: !!storeId,
    },
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      setGetPerMachineSaleDataLoading(true);
    } else {
      setGetPerMachineSaleDataLoading(false);
    }
  }, [isLoading, isFetching]);

  return {
    getPerMachineSaleData,
    getPerMachineSaleDataLoading,
  };
};
