import GrantAuthorityMain from '@components/grantAuthority/GrantAuthorityMain';
import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';
import React from 'react';

const GrantAuthority = () => {
  return (
    <div id="grant_authority_container">
      <DashBoardHeader />
      <GrantAuthorityMain />
    </div>
  );
};

export default GrantAuthority;
