const TotalSalesListsSkeleton = () => {
  return (
    <li className="sales_list skeleton">
      {/* <li className="skeleton"> */}
      <section className="top_section">
        <div className="sales_date">
          <span className="date">2024-05-28</span>
          <span className="time">02:08:30</span>
        </div>
        <div className="pay_type">카드</div>
      </section>

      <section className="bottom_section">
        <div className="money_wrapper">
          <div className="price">
            <div className="text">금액</div>
            <div className="price_money">30,000</div>
          </div>
          <div className="vat">
            <div className="text">부가세</div>
            <div className="vat_money">3,000</div>
          </div>

          {/* <div className="charge">
            <div className="text">수수료</div>
            <div className="charge_money">3,000</div>
          </div> */}
        </div>

        <div className="arrow_icon_wrapper"></div>
      </section>
    </li>
  );
};

export default TotalSalesListsSkeleton;
