import React, { useEffect, useRef, useState } from 'react';
import { addDays, subDays, subMonths } from 'date-fns';
import { DateRange, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import calendarIcon from '@assets/icon/calendar_icon(block).png';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import { ko } from 'date-fns/locale'; // Import Korean locale from date-fns
import { datePickerProps } from '@dataTypes/totalSalesTypes';

interface DatePickerProps {
  showDatePicker: boolean;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  newDate: datePickerProps[];
  setNewDate: React.Dispatch<React.SetStateAction<datePickerProps[]>>;
}

const DatePicker = ({
  showDatePicker,
  setShowDatePicker,
  newDate,
  setNewDate,
}: DatePickerProps) => {
  const [prevDateState, setPrevDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleChange = (item: any) => {
    const btnToday = document.getElementById('btn-today');
    const btnDayAgo = document.getElementById('btn-day-ago');
    const btnWeekAgo = document.getElementById('btn-week-ago');
    const btnMonthAgo = document.getElementById('btn-month-ago');

    const removeActiveClass = () => {
      [btnToday, btnDayAgo, btnWeekAgo, btnMonthAgo].forEach((btn) => {
        if (btn) btn.classList.remove('active');
      });
    };

    removeActiveClass();

    setPrevDateState([item.selection]);
  };

  const handleApply = () => {
    setNewDate(prevDateState);
    setShowDatePicker(false);
  };

  const selectToday = () => {
    setPrevDateState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const selectDayAgo = () => {
    setPrevDateState([
      {
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const selectWeekAgo = () => {
    setPrevDateState([
      {
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const selectMonthAgo = () => {
    setPrevDateState([
      {
        startDate: subMonths(new Date(), 1),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const dateDisplayWrapper = document.querySelector('.rdrDateDisplayWrapper');

      if (dateDisplayWrapper) {
        clearInterval(interval);

        const btnWrapper = document.createElement('div');
        btnWrapper.className = 'btn_wrapper';
        btnWrapper.innerHTML = `
          <button class='btn' id='btn-today'>당일</button>
          <button class='btn' id='btn-day-ago'>하루 전</button>
          <button class='btn' id='btn-week-ago'>일주일 전</button>
          <button class='btn' id='btn-month-ago'>한 달 전</button>
        `;
        dateDisplayWrapper.appendChild(btnWrapper);

        const btnToday = document.getElementById('btn-today');
        const btnDayAgo = document.getElementById('btn-day-ago');
        const btnWeekAgo = document.getElementById('btn-week-ago');
        const btnMonthAgo = document.getElementById('btn-month-ago');

        const removeActiveClass = () => {
          [btnToday, btnDayAgo, btnWeekAgo, btnMonthAgo].forEach((btn) => {
            if (btn) btn.classList.remove('active');
          });
        };

        if (btnToday) {
          btnToday.addEventListener('click', () => {
            removeActiveClass();
            btnToday.classList.add('active');
            selectToday();
          });
          btnToday.click(); // selectToday 함수를 바로 호출
        }
        if (btnDayAgo) {
          btnDayAgo.addEventListener('click', () => {
            removeActiveClass();
            btnDayAgo.classList.add('active');
            selectDayAgo();
          });
        }
        if (btnWeekAgo) {
          btnWeekAgo.addEventListener('click', () => {
            removeActiveClass();
            btnWeekAgo.classList.add('active');
            selectWeekAgo();
          });
        }
        if (btnMonthAgo) {
          btnMonthAgo.addEventListener('click', () => {
            removeActiveClass();
            btnMonthAgo.classList.add('active');
            selectMonthAgo();
          });
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [showDatePicker]);

  // useEffect(()=>{
  //   const btnToday = document.getElementById('btn-today');
  //   const btnDayAgo = document.getElementById('btn-day-ago');
  //   const btnWeekAgo = document.getElementById('btn-week-ago');
  //   const btnMonthAgo = document.getElementById('btn-month-ago');

  //   const removeActiveClass = () => {
  //     [btnToday, btnDayAgo, btnWeekAgo, btnMonthAgo].forEach((btn) => {
  //       if (btn) btn.classList.remove('active');
  //     });
  //   };

  //   removeActiveClass()
  // },[prevDateState])

  const showDatePickerWithPrevState = () => {
    setPrevDateState(newDate);
    setShowDatePicker(true);
  };

  const formattedDateHandler = (date: Date) => {
    const newDate = new Date(date);
    const formattedStartDate = `${newDate.getFullYear()}년 ${newDate.getMonth() + 1}월 ${newDate.getDate()}일`;
    return formattedStartDate;
  };

  return (
    <div className="date_range_wrapper">
      <div className="picked_date" onClick={showDatePickerWithPrevState}>
        <span>날짜</span>
        <span className="date_title">
          {formattedDateHandler(newDate[0].startDate)} - {formattedDateHandler(newDate[0].endDate)}
        </span>
      </div>

      <Offcanvas
        id="date_range_modal_wrapper"
        show={showDatePicker}
        onHide={() => setShowDatePicker(false)}
        placement="bottom"
      >
        <Offcanvas.Header closeButton className="date_rane_header">
          <Offcanvas.Title>날짜선택</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="date_range_body">
          <DateRange
            locale={ko}
            editableDateInputs={true}
            onChange={handleChange}
            minDate={addDays(new Date(), -300)}
            maxDate={new Date()}
            direction="vertical"
            calendarFocus="backwards"
            scroll={{ enabled: true }}
            moveRangeOnFirstSelection={false}
            ranges={prevDateState}
          />
          <Button className="submit_btn" onClick={handleApply}>
            적용
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default DatePicker;
