import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import signUpImg from '@assets/img/sign_up_img.png';

const EndSignUp = () => {
  const navigatioin = useNavigate();
  const { storeAccess, storeCnt } = useParams();

  return (
    <div id="end_sign_up_container">
      <div className="text_wrapper">
        <div className="title">SMARTLAB 회원가입이</div>
        <div className="title with_br">완료되었습니다.</div>

        {!storeAccess ? (
          <p className="sub_title">관리자에게 권한을 요청한 후 지점을 관리해보세요.</p>
        ) : (
          <p className="sub_title">총 {storeCnt}개의 지점에 대해 권한이 부여되었습니다.</p>
        )}
      </div>
      <img src={signUpImg} className="sign_up_img" alt="회원가입 완료 이미지" />

      <Button className="go_btn" onClick={() => navigatioin('/signin')}>
        로그인하러 가기
      </Button>
    </div>
  );
};

export default EndSignUp;
