import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import logo from '@assets/logo/smartLab_logo(black_horizontal).png';

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  text: string;
}

const CustomModal = ({ show, text, onHide }: CustomModalProps) => {
  return (
    <Modal
      id={'custom_modal'}
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="header_wrapper">
          <img src={logo} className="logo" alt="로고" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_text">{text}</Modal.Body>
      <Modal.Footer className="custom_modal_footer">
        <Button className="ok_btn" onClick={onHide}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
