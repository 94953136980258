import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { instance } from '../../hejHomeApis/index';
import onOffIconBlack from '@assets/icon/on_off_icon(black).png';
import onOffIconColor from '@assets/icon/on_off_icon(color).png';
import temperatureIcon from '@assets/icon/temperature_icon(black).png';
import humidityIcon from '@assets/icon/humidity_icon(black).png';
import {
  EAirconditionerMode,
  EAirconditionerSpeed,
  IAirconditionerState,
  IControlPlugAirconditionerRequest,
} from '@dataTypes/hejHomeTypes';
import { controlDevice } from '../../hejHomeApis/deviceList';

interface AirconditionerControlModalProps {
  airconditionerState: IAirconditionerState | undefined;
  setAirconditionerState: React.Dispatch<React.SetStateAction<IAirconditionerState | undefined>>;
  currentTemperature: string;
  currentHumidity: string;
  show: boolean;
  closeModal: () => void;
  name: string;
  deviceOnline: boolean;
}

const AirconditionerControlModal = ({
  airconditionerState,
  setAirconditionerState,
  show,
  closeModal,
  name,
  currentHumidity,
  currentTemperature,
  deviceOnline,
}: AirconditionerControlModalProps) => {
  const accessToken = instance.defaults.headers['Authorization'];

  const onOffHandler = async () => {
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!deviceOnline) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (airconditionerState && airconditionerState.id) {
      const requirments: IControlPlugAirconditionerRequest = {
        power: airconditionerState.deviceState.power === '켜짐' ? false : true,
      };
      try {
        //openApi
        const result = await controlDevice(airconditionerState.id, requirments);
        if (result.status === 200) {
          setAirconditionerState((prevState) => {
            if (!prevState) return prevState; // 상태가 undefined일 경우 처리
            return {
              ...prevState,
              deviceState: {
                ...prevState.deviceState,
                power: airconditionerState.deviceState.power === '켜짐' ? '꺼짐' : '켜짐',
              },
            };
          });
        } else {
          console.error('Request failed with status: ', result.status);
        }
      } catch (error) {
        console.error('Error in controlDevice: ', error);
      }
    }
  };

  const plusTemperature = async () => {
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!deviceOnline) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (airconditionerState && airconditionerState.id) {
      const requirments: IControlPlugAirconditionerRequest = {
        temperature: Number(airconditionerState.deviceState.temperature) + 1,
        power: airconditionerState.deviceState.power === '켜짐' ? true : false,
        fanSpeed: airconditionerState.deviceState.fanSpeed.toString(),
        mode: airconditionerState.deviceState.mode.toString(),
      };
      try {
        //openApi
        const result = await controlDevice(airconditionerState.id, requirments);
        if (result.status === 200) {
          setAirconditionerState((prevState) => {
            if (!prevState) return prevState; // 상태가 undefined일 경우 처리
            return {
              ...prevState,
              deviceState: {
                ...prevState.deviceState,
                temperature: (Number(airconditionerState.deviceState.temperature) + 1).toString(),
              },
            };
          });
        } else {
          console.error('Request failed with status: ', result.status);
        }
      } catch (error) {
        console.error('Error in controlDevice: ', error);
      }
    }
  };

  const minusTemperature = async () => {
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!deviceOnline) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (airconditionerState && airconditionerState.id) {
      const requirments: IControlPlugAirconditionerRequest = {
        temperature: Number(airconditionerState.deviceState.temperature) - 1,
        power: airconditionerState.deviceState.power === '켜짐' ? true : false,
        fanSpeed: airconditionerState.deviceState.fanSpeed.toString(),
        mode: airconditionerState.deviceState.mode.toString(),
      };
      try {
        //openApi
        const result = await controlDevice(airconditionerState.id, requirments);
        if (result.status === 200) {
          setAirconditionerState((prevState) => {
            if (!prevState) return prevState; // 상태가 undefined일 경우 처리
            return {
              ...prevState,
              deviceState: {
                ...prevState.deviceState,
                temperature: (Number(airconditionerState.deviceState.temperature) - 1).toString(),
              },
            };
          });
        } else {
          console.error('Request failed with status: ', result.status);
        }
      } catch (error) {
        console.error('Error in controlDevice: ', error);
      }
    }
  };

  const modeHandler = async (type: EAirconditionerMode) => {
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!deviceOnline) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (airconditionerState && airconditionerState.id) {
      const requirments: IControlPlugAirconditionerRequest = {
        temperature: Number(airconditionerState.deviceState.temperature),
        power: airconditionerState.deviceState.power === '켜짐' ? true : false,
        fanSpeed: airconditionerState.deviceState.fanSpeed.toString(),
        mode: type,
      };
      try {
        //openApi
        const result = await controlDevice(airconditionerState.id, requirments);
        if (result.status === 200) {
          setAirconditionerState((prevState) => {
            if (!prevState) return prevState; // 상태가 undefined일 경우 처리
            return {
              ...prevState,
              deviceState: {
                ...prevState.deviceState,
                mode: +type,
              },
            };
          });
        } else {
          console.error('Request failed with status: ', result.status);
        }
      } catch (error) {
        console.error('Error in controlDevice: ', error);
      }
    }
  };

  const speedHandler = async (speed: EAirconditionerSpeed) => {
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!deviceOnline) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (airconditionerState && airconditionerState.id) {
      const requirments: IControlPlugAirconditionerRequest = {
        temperature: Number(airconditionerState.deviceState.temperature),
        power: airconditionerState.deviceState.power === '켜짐' ? true : false,
        fanSpeed: speed,
        mode: airconditionerState.deviceState.mode.toString(),
      };
      try {
        //openApi
        const result = await controlDevice(airconditionerState.id, requirments);
        if (result.status === 200) {
          setAirconditionerState((prevState) => {
            if (!prevState) return prevState; // 상태가 undefined일 경우 처리
            return {
              ...prevState,
              deviceState: {
                ...prevState.deviceState,
                fanSpeed: +speed,
              },
            };
          });
        } else {
          console.error('Request failed with status: ', result.status);
        }
      } catch (error) {
        console.error('Error in controlDevice: ', error);
      }
    }
  };

  return (
    <Offcanvas
      id="airconditioner_control_modal_wrapper"
      show={show}
      onHide={closeModal}
      placement="bottom"
    >
      <Offcanvas.Header closeButton className="modify_modal_header">
        <Offcanvas.Title>{name}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="airconditioner_modal_body">
        <div className="current_temperature_wrapper">
          <div className="temperature_wrapper">
            <div className="title">현재 온도</div>
            <div className="text_wrapper">
              <img className="temperature_icon" alt="온도 아이콘" src={temperatureIcon} />
              <span className="text">{currentTemperature}°C</span>
            </div>
          </div>
          <div className="humidity_wrapper">
            <div className="title">현재 습도</div>
            <div className="text_wrapper">
              <img className="humidity_icon" alt="습도 아이콘" src={humidityIcon} />
              <span className="text">{currentHumidity}%</span>
            </div>
          </div>
        </div>

        <div className="temperature_control_wrapper">
          <div className="btn_wrapper" onClick={plusTemperature}>
            <div className="btn plus">+</div>
          </div>
          <div className="current_aircon_temperature">
            {airconditionerState?.deviceState.temperature}
          </div>
          <div className="btn_wrapper" onClick={minusTemperature}>
            <div className="btn minus">-</div>
          </div>
        </div>

        <section className="air_mode_wrapper">
          <div className="title">모드</div>
          <div className="btn_wrapper">
            <button
              className={`${airconditionerState?.deviceState.mode === 0 ? 'active' : ''}`}
              onClick={() => modeHandler(EAirconditionerMode.cold)}
            >
              냉방
            </button>
            <button
              className={`${airconditionerState?.deviceState.mode === 1 ? 'active' : ''}`}
              onClick={() => modeHandler(EAirconditionerMode.hot)}
            >
              난방
            </button>
            <button
              className={`${airconditionerState?.deviceState.mode === 2 ? 'active' : ''}`}
              onClick={() => modeHandler(EAirconditionerMode.auto)}
            >
              자동
            </button>
            <button
              className={`${airconditionerState?.deviceState.mode === 3 ? 'active' : ''}`}
              onClick={() => modeHandler(EAirconditionerMode.light)}
            >
              송풍
            </button>
            <button
              className={`${airconditionerState?.deviceState.mode === 4 ? 'active' : ''}`}
              onClick={() => modeHandler(EAirconditionerMode.humidifier)}
            >
              제습
            </button>
          </div>
        </section>

        <section className="air_speed_wrapper">
          <div className="title">풍속</div>
          <div className="btn_wrapper">
            <button
              className={`${airconditionerState?.deviceState.fanSpeed === +EAirconditionerSpeed.low ? 'active' : ''}`}
              onClick={() => speedHandler(EAirconditionerSpeed.low)}
            >
              낮음
            </button>
            <button
              className={`${airconditionerState?.deviceState.fanSpeed === +EAirconditionerSpeed.middle ? 'active' : ''}`}
              onClick={() => speedHandler(EAirconditionerSpeed.middle)}
            >
              중간
            </button>
            <button
              className={`${airconditionerState?.deviceState.fanSpeed === +EAirconditionerSpeed.high ? 'active' : ''}`}
              onClick={() => speedHandler(EAirconditionerSpeed.high)}
            >
              높음
            </button>
            <button
              className={`${airconditionerState?.deviceState.fanSpeed === +EAirconditionerSpeed.auto ? 'active' : ''}`}
              onClick={() => speedHandler(EAirconditionerSpeed.auto)}
            >
              자동
            </button>
          </div>
        </section>

        <div
          className={`on_off_btn_wrapper ${airconditionerState?.deviceState.power === '켜짐' ? 'on' : 'off'}`}
          onClick={onOffHandler}
        >
          <img
            className={`button_icon ${airconditionerState?.deviceState.power === '켜짐' ? 'on' : 'off'}`}
            src={
              airconditionerState?.deviceState.power === '켜짐' ? onOffIconColor : onOffIconBlack
            }
            alt="전원 버튼"
          />
          <span>전원</span>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AirconditionerControlModal;
