import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

interface NavActive {
  dashboard: boolean;
  totalsales: boolean;
  permachine: boolean;
  grant: boolean;
}

const DashBoardNav = () => {
  const location = useLocation();
  const navInitialHandler = () => {
    const pathName = location.pathname.split('/')[2];

    return {
      dashboard: pathName === 'dashboard',
      totalsales: pathName === 'totalsales',
      permachine: pathName === 'permachine',
      grant: pathName === 'grant',
      hejHome: pathName === 'hejHome',
    };
  };
  const navigation = useNavigate();
  const [navActive, setNavActive] = useState(navInitialHandler());

  const navActiveHandler = (nav: string) => {
    setNavActive((prevNavActive) => ({
      ...prevNavActive,
      [nav]: true,
      dashboard: nav === 'dashboard',
      totalsales: nav === 'totalsales',
      permachine: nav === 'permachine',
      grant: nav === 'grant',
    }));

    switch (nav) {
      case 'dashboard':
        navigation('/admin/dashboard');
        break;
      case 'totalsales':
        navigation('/admin/totalsales');
        break;
      case 'permachine':
        navigation('/admin/permachine');
        break;
      case 'grant':
        navigation('/admin/grant');
        break;
      default:
        break;
    }
  };

  const findActiveKey = (obj: NavActive): keyof NavActive | undefined => {
    return (Object.keys(obj) as (keyof NavActive)[]).find((key) => obj[key]);
  };

  return (
    <Nav fill variant="tabs" defaultActiveKey={findActiveKey(navActive)} id="dashboard_nav">
      <Nav.Item onClick={() => navActiveHandler('dashboard')} className="nav_custom_item">
        <Nav.Link eventKey="dashboard" className="nav_section">
          <div className="type_text_wrapper">
            <span className="type">메인화면</span>
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item onClick={() => navActiveHandler('totalsales')} className="nav_custom_item">
        <Nav.Link eventKey="totalsales" className="nav_section">
          <div className="type_text_wrapper">
            <span className="type">통합매출</span>
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item onClick={() => navActiveHandler('permachine')} className="nav_custom_item">
        <Nav.Link eventKey="permachine" className="nav_section">
          <div className="type_text_wrapper">
            <span className="type">단말기별 조회</span>
          </div>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item onClick={() => navActiveHandler('grant')} className="nav_custom_item">
        <Nav.Link eventKey="grant" className="nav_section">
          <div className="type_text_wrapper">
            <span className="type">관리자 권한</span>
          </div>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default DashBoardNav;
