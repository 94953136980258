import { instance } from './index';

export const signInRequest = async (loginId: string, password: string, rememberMe: boolean) => {
  const data = { loginId, password, rememberMe };
  const response = await instance.post('/login', data);
  return response.data;
};

export const logoutRequest = async () => {
  const response = await instance.get('/logout');
  return response.data;
};

export const checkLogin = async () => {
  const response = await instance.get('/check-login');
  return response.data;
};
