import React from 'react';
import mainIcon from '@assets/icon/temperature_humidity_main_icon.png';
import temperatureIcon from '@assets/icon/temperature_icon(black).png';
import humidityIcon from '@assets/icon/humidity_icon(black).png';

interface CurrentTemperatureProps {
  temperature: string;
  humidity: string;
  battery: string;
}

const CurrentTemperature = ({ temperature, humidity, battery }: CurrentTemperatureProps) => {
  return (
    <li className="device_list temperature">
      <div className="top_wrapper">
        <img className="main_icon" alt="온습도센서 아이콘" src={mainIcon} />
        <span className="title">온·습도센서</span>

        <img className="battery" alt="배터리 아이콘" src={battery} />
        {/* <span className="battery">{battery}</span> */}
      </div>

      <div className="current_temperature_wrapper">
        <div className="temperature_wrapper">
          <img className="temperature_icon" alt="온도 아이콘" src={temperatureIcon} />
          <span className="text">{temperature}°C</span>
        </div>
        <div className="line"></div>
        <div className="humidity_wrapper">
          <img className="humidity_icon" alt="습도 아이콘" src={humidityIcon} />
          <span className="text">{humidity}%</span>
        </div>
      </div>
    </li>
  );
};

export default CurrentTemperature;
