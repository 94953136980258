import React, { useEffect, useRef, useState } from 'react';
import DatePicker from './DatePicker';
import SelectTypes from './SelectTypes';
import { Button } from 'react-bootstrap';
import TotalSalesLists from './TotalSalesLists';
import SalesDetailModal from './SalesDetailModal';
import { ITotalSalesResponse, PAYMETHOD } from '@dataTypes/totalSalesTypes';
import { useGetTotalSalesLists } from '@hooks/totalSales/useGetTotalSalesLists';
import { format } from 'date-fns';
import storeInfo from '../../recoil/storeInfo/atom';
import { useGetMachineLists } from '@hooks/perMachine/useGetMachineLists';
import { useInView } from 'react-intersection-observer';
import TotalSalesListsSkeleton from './TotalSalesListsSkeleton';
import { useRecoilValue } from 'recoil';

const SelectDateWithSalesContainer = () => {
  const { ref, inView } = useInView();
  const [showSalesDetail, setShowSalesDetail] = useState(false);
  const [showDetailId, setShowDetailId] = useState<number | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [newDate, setNewDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [selectedMachine, setSelectedMachine] = useState<PAYMETHOD>(PAYMETHOD.TOTAL);
  const [selectedCatId, setSelectedCatId] = useState('');

  const formattedStartDate = format(newDate[0].startDate, 'yyMMdd');
  const formattedEndDate = format(newDate[0].endDate, 'yyMMdd');
  const storeInfoState = useRecoilValue(storeInfo);

  const { getMachineListsData, getMachineListsLoading, getMachineListsFetching } =
    useGetMachineLists(storeInfoState.currentStoreId);

  const {
    totalSalesLists,
    isFetchingNextPage,
    isError,
    isFetching,
    isLoading,
    fetchNextPage,
    setSearchTrigger,
    hasNextPage,
  } = useGetTotalSalesLists({
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    catId: selectedCatId,
    method: selectedMachine,
    storeId: storeInfoState.currentStoreId,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    setSelectedCatId('');
  }, [storeInfoState]);

  const handleSearch = () => {
    setSearchTrigger(true);
  };

  const handleSalesDetailShow = (id: number) => {
    setShowDetailId(id);
    setShowSalesDetail(true);
  };
  const handleSalesDetailClose = () => setShowSalesDetail(false);

  const detailModalData = totalSalesLists?.find((list) => list.id === showDetailId);

  if (isError) {
    return <>error</>;
  }

  return (
    <div className="select_date_with_sales_container">
      <DatePicker
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
        newDate={newDate}
        setNewDate={setNewDate}
      />
      <SelectTypes
        setSelectedMachine={setSelectedMachine}
        machineLists={getMachineListsData}
        setSelectedCatId={setSelectedCatId}
      />
      <div className="btn_wrapper" onClick={handleSearch}>
        <Button className="search_btn">검색</Button>
      </div>

      {/* <TotalSalesLists showDetailPayment={handleSalesDetailShow} /> */}
      <ul className="total_sales_lists_wrapper">
        {!isFetchingNextPage && totalSalesLists?.length === 0 ? (
          <div className="no_data">데이터가 없습니다.</div>
        ) : (
          <>
            {totalSalesLists?.map((list, index) => (
              <TotalSalesLists key={index} showDetailPayment={handleSalesDetailShow} item={list} />
            ))}
            {isFetchingNextPage || isFetching ? (
              <TotalSalesListsSkeleton />
            ) : (
              <div ref={ref} className="targetRef"></div>
            )}
          </>
        )}

        {totalSalesLists?.length > 0 && !hasNextPage && (
          <div className="last_page">마지막 페이지 입니다.</div>
        )}
      </ul>

      {detailModalData && (
        <SalesDetailModal
          show={showSalesDetail}
          handleClose={handleSalesDetailClose}
          detailModalData={detailModalData}
        />
      )}
    </div>
  );
};

export default SelectDateWithSalesContainer;
