import React from 'react';

const TypeTwoText = () => {
  return (
    <>
      <div className="main_text">SMARTLAB 서비스 이용 약관</div>
      <p>
        제 1조 (목적) SMARTLAB 서비스 이용약관(이하 “약관”이라 합니다)은 주식회사 위커스(이하
        “회사”라 합니다)가 제공하는 서비스와 관련하여 회사와 이용고객(또는 회원) 간에 서비스의 이용
        조건 및 절차, 회사와 회원 간의 권리, 의무 및 책임 사항 기타 필요한 사항을 규정함을 목적으로
        합니다.
      </p>
      <p>
        제 2조 (용어) 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같으며, 정의되지 않은 용어에
        대한 해석은 관계법령 및 서비스별 안내에서 정하는 바에 따릅니다.
      </p>
      <ul>
        <li>
          1. SMARTLAB 서비스(이하 “서비스”라 합니다): 회사가 제공하는 예약(이용권, 스터디룸, 사물함
          등으로 한정되지 않음)관련 상품∙용역∙서비스 등(이하 “상품 등”이라 합니다)에 대한
          예약∙구매∙정보제공∙추천 등을 위한 온/오프라인 플랫폼 서비스를 의미합니다. 서비스는
          구현되는 장치나 단말기(PC, TV, 출입/현장결제 단말기, 기타 휴대형 단말기 등의 각종 유무선
          장치를 포함하며 이에 한정되지 않음)와 상관없이 SMARTLAB 및 SMARTLAB 관련 플랫폼 등의 제반
          서비스를 의미하며, 회사가 공개한 API를 이용하여 제3자가 개발 또는 구축한 프로그램이나
          서비스를 통하여 이용자에게 제공되는 경우를 포함합니다. 서비스는 기술이 발전함에 따라
          지속적으로 함께 변화∙성장해 나갑니다.
        </li>
        <li>
          2. 회원: 회사의 서비스에 접속하여 본 약관에 동의하고 ID(이메일)와 PASSWORD를 발급 받았으며
          회사가 제공하는 서비스를 이용하는 고객을 포괄적으로 의미합니다. SMARTLAB 서비스를 이용하는
          일반 유저와 판매자 모두를 통칭합니다.
        </li>
        <li>
          3. 판매자: 공간공유(스터디카페, 스터디룸 등으로 한정되지 않음) 관련 서비스나 제품을
          판매하는 직종에 종사하는 회원 중 회사가 지정한 절차 및 검수를 마치고 해당 회원의 서비스와
          제품에 대한 정보 및 상담을 제공할 수 있는 회원을 의미합니다.
        </li>
        <li>
          4. 회원정보: 회사가 가입신청자에게 회원가입 신청양식(이하 &apos;신청양식&apos;이라
          합니다)에 기재를 요청하는 가입신청자의 개인정보와 회원의 식별과 서비스 이용을 위하여
          회원이 입력하고 서비스 내 공개된 개인정보를 의미합니다.
        </li>
        <li>
          5. ID(고유번호): 회원 식별과 회원의 본 서비스 이용을 위하여 회원이 선정하고 회사가
          승인하는 문자와 숫자의 조합을 말합니다. 본 서비스에서는 E-mail 주소를 ID로 사용합니다.
        </li>
        <li>
          6. PASSWORD(비밀번호): 회원의 정보 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합을
          말합니다.
        </li>
        <li>
          7. 게시물: 회원이 회사가 제공하는 서비스에 게시 또는 등록하는 부호(URL 포함), 문자, 음성,
          음향, 영상(동영상 포함), 이미지(사진 포함), 파일 등 일체의 정보를 말합니다.
        </li>
        <li>
          8. 이용권: 회원이 서비스를 이용할 때 표시된 금액 또는 비율만큼 이용 금액을 할인 받을 수
          있는 할인권∙우대권 등(온라인∙모바일∙오프라인 등 형태를 불문)를 말합니다. 이용권의 종류 및
          내용은 회사의 정책에 따라 달라질 수 있습니다. 9. 포인트: 회사가 회원의 서비스 이용에 따른
          혜택 또는 서비스 이용상 편의를 위해 회원에게 제공하는 것으로서 서비스 내 상품 등 결제시
          활용할 수 있는 수치화 된 가상의 데이터를 말합니다. 구체적인 이용 방법, 그 명칭(예:
          마일리지 등) 및 현금 환급가능성 등은 회사의 정책에 따라 달라질 수 있습니다.
        </li>
      </ul>
      <p>제3조 (약관의 효력 및 변경)</p>
      <ul>
        <li>
          ① 본 약관은 회원이 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 게시하거나 기타의
          방법으로 회원에게 공지함으로써 효력이 발생합니다.
        </li>
        <li>
          ② 회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
          관계법령에 위배되지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
        </li>
        <li>
          ③ 회사는 약관을 개정할 경우 그 개정이유 및 적용일자를 명시하여 현행 약관과 함께 적용일자
          7일전부터 적용일 전일까지 제1항의 방법으로 공지합니다. 다만, 회원의 권리 또는 의무에 관한
          중요한 규정의 변경은 최소한 30일전에 공지하고 개정약관을 회원이 등록한 E-mail로 발송하여
          통지합니다.
        </li>
        <li>
          ④ 회사가 제3항에 따라 개정약관을 공지 또는 통지하였음에도 불구하고 회원이 명시적으로
          거부의사를 표시하지 아니하는 경우 회원이 개정약관에 동의한 것으로 봅니다.
        </li>
        <li>
          ⑤ 회원은 변경된 약관에 동의하지 아니하는 경우 서비스의 이용을 중단하고 이용계약을 해지할
          수 있습니다.
        </li>
        <li>
          ⑥ 본 약관에 동의하는 것은 서비스를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에
          동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에 대하여
          회사는 책임을 지지 않습니다.
        </li>
      </ul>
      <p>
        제4조 (약관 외 준칙) 본 약관에 명시되지 않은 사항에 대해서는 정보통신망 이용촉진 및 정보보호
        등에 관한 법률, 약관의 규제에 관한 법률, 전기통신사업법 등 관계법령 및 회사가 정한 서비스의
        세부이용지침 등의 규정에 따릅니다.
      </p>
      <p>제5조 (이용계약의 성립)</p>
      <ul>
        <li>
          ① 서비스 이용계약은 회사가 정한 가입 양식에 따라 회원정보(전자우편주소, 비밀번호, 이름
          등)를 기입하여 회원가입신청을 하고 회사가 이러한 신청에 대하여 승인함으로써 체결됩니다.
        </li>
        <li>
          ② 판매자의 경우에는 공간공유(스터디카페, 스터디룸 등으로 한정하지 않음) 관련 서비스나
          제품을 판매하는 직종에 종사하는 회원이 회사가 정한 절차에 따라 정보를 기입하여 판매자 전환
          신청을 하고 회사가 해당 판매자 전환 신청을 승인하면 판매자로서 서비스를 이용할 수
          있습니다.
        </li>
        <li>
          ③ 가입신청자가 회원정보를 제출하는 것은 회원가입 화면에 노출되어 있는 본 약관 및
          개인정보취급방침의 내용을 숙지하고, 회사가 서비스 이용을 위해 운영하는 각종 정책(저작권
          정책, 운영 방침 등)과 수시로 공지하는 사항을 준수하는 것에 대해 동의하는 것으로 봅니다.
        </li>
      </ul>
      <p>제6조 (이용신청에 대한 승낙 및 제한)</p>
      <li>
        ① 회사는 서비스의 이용을 신청한 가입신청자에 대하여 업무상ㆍ기술상 지장이 없는 한 접수순서에
        따라 서비스의 이용을 승낙합니다.
      </li>
      <li>
        ② 타인의 개인정보를 도용하는 등 부정한 목적 또는 방법으로 이용신청을 한 회원의 ID는 사전
        통지 없이 이용 계약이 해지될 수 있으며, 당해 회원은 관계법령에 따라 처벌을 받을 수 있습니다.
      </li>
      <li>③ 회사는 다음 각 호에 해당하는 이용신청에 대하여는 승낙을 하지 않을 수 있습니다.</li>
      <section>
        <div>1. 기술상 서비스 제공이 불가능한 경우</div>
        <div>2. 신청양식의 기재사항을 누락하거나 오기하여 신청하는 경우</div>
        <div>3. 사회의 안녕질서 또는 미풍양속을 저해하거나 저해할 목적으로 신청한 경우</div>
        <div>4. 회원의 귀책사유에 의하여 회원자격을 상실한 적이 있는 경우.</div>
        <div>5. 기타 회사가 정한 이용요건에 충족되지 않았을 경우</div>
      </section>
      <li>
        ④ 회사는 서비스를 이용하는 회원에 대하여 회원의 종류 및 등급별로 구분하여 이용시간,
        이용회수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
      </li>
      <p>제7조 (회원정보의 변경)</p>
      <ul>
        <li>① 회원은 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.</li>
        <li>
          ② 회원은 서비스를 이용하면서 회사에 제출한 회원정보가 변경되었을 경우 개인정보 설정
          화면에서 회원정보를 수정하거나 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다.
        </li>
        <li>③ 회원정보를 수정하지 않음으로 인하여 발생하는 모든 책임은 회원에게 있습니다.</li>
        <li>
          ④ 전문가가 회사에 제출한 전문가 정보를 변경했을 경우, 회사가 실시하는 변경된 정보에 대해
          검수를 통과해야만 변경된 정보가 서비스에 반영됩니다.
        </li>
      </ul>
      <p>제8조 (서비스의 이용)</p>
      <ul>
        <li>① 회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시합니다.</li>
        <li>
          ② 회사의 업무상ㆍ기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 서비스에
          공지하거나 회원에게 이를 통지합니다.
        </li>
        <li>
          ③ 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상ㆍ기술상 또는
          서비스 운영정책상 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에
          이를 공지합니다.
        </li>
        <li>
          ④ 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수
          있으며 이 경우 그 내용을 공지합니다.
        </li>
        <li>
          ⑤ 회사는 서비스 내의 개별서비스에 대한 별도의 약관을 둘 수 있으며, 개별서비스에서 별도로
          적용되는 약관에 대한 동의는 회원이 개별서비스를 최초로 이용할 경우 별도의 동의절차를
          거치게 됩니다.
        </li>
        <li>
          ⑥ 무료로 제공되는 서비스의 특성상 본조에 따른 서비스의 전부 또는 일부 종료 시 관련법령에서
          특별히 정하지 않는 한 회원에게 별도의 보상을 하지 않습니다.
        </li>
        <li>
          ⑦ 서비스 이용에 관한 개별 안내, 상품등에 대한 정보, 예약시 유의사항, 취소∙환불정책 등은
          개별 서비스 이용안내∙소개 등을 통해 제공하고 있습니다.
        </li>
        <li>
          ⑧ 회원은 전항의 안내∙소개 등을 충분히 숙지하고 서비스를 이용하여야 합니다. 회사는
          통신판매중개자로서 통신판매의 당사자가 아니고, 판매자가 상품등 이용에 관한 이용정책이나
          예약에 관한 취소∙환불정책을 별도로 운영하는 경우가 있을 수 있으므로, 회원은 상품등 이용
          또는 예약시 반드시 그 내용을 확인해야 합니다. 회원이 이 내용을 제대로 숙지하지 못해 발생한
          피해에 대해서는 회사가 책임을 부담하지 않습니다.
        </li>
      </ul>
      <p>제9조 (서비스의 변경 및 중지)</p>
      <ul>
        <li>
          ① 회사는 서비스(개별서비스 포함)를 변경하여야 하는 상당한 이유가 있는 경우 변경될 서비스의
          내용 및 제공일자를 제15조에서 정한 방법으로 회원에게 통지하고 서비스를 변경하여 제공할 수
          있습니다.
        </li>
        <li>
          ② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수
          있습니다.
        </li>
        <section>
          <div>1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</div>
          <div>2. 회원이 회사의 영업활동을 방해하는 경우</div>
          <div>
            3. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는
            경우
          </div>
          <div>
            4. 판매자와의 계약종료 등과 같은 회사의 제반 사정으로 서비스를 유지할 수 없는 경우
          </div>
          <div>5. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</div>
        </section>
      </ul>
      <p>제10조 (정보의 제공 및 광고의 게재)</p>
      <ul>
        <li>
          ① 회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 E-mail, 서신우편,
          SMS(MMS)등으로 회원에게 제공할 수 있습니다.
        </li>
        <li>
          ② 회사는 서비스를 운영함에 있어 회사 또는 제휴사의 서비스 관련 각종 광고를 서비스 화면
          또는 게시물과 결합하여 게재하거나 회원의 동의를 얻어 E-mail 및 서신 우편, SMS(MMS) 등의
          방법으로 회원에게 제공할 수 있습니다.
        </li>
        <li>
          ③ 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에
          참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주간의 문제입니다.
          만약, 회원과 광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와
          관련하여 회사는 어떠한 책임도 지지 않습니다.
        </li>
        <li>
          ④ 회사는 다음 각호에 해당하는 경우 회원의 동의여부와 상관없이 전자우편으로 발송할 수
          있습니다.
        </li>
        <section>
          <div>
            1. 이용 신청에서 입력한 전자우편 주소의 소유를 확인하기 위해서 인증메일을 발송하는 경우
          </div>
          <div>2. 회원의 정보가 변경되어 확인하기 위해서 인증메일을 발송하는 경우</div>
          <div>
            3. 기타 서비스를 제공함에 있어 회원이 반드시 알아야 하는 중대한 정보라고 회사가 판단하는
            경우
          </div>
        </section>
      </ul>
      <p>제11조 (게시물에 대한 책임)</p>
      <ul>
        <li>
          ① 회사는 회원이 게시하거나 전달하는 서비스 내의 게시물이 다음 각 호의 경우에 해당한다고
          판단되는 경우 사전 통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지
          않습니다.
        </li>
        <section>
          <div>
            1. 회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
          </div>
          <div>2. 공공질서 및 미풍양속에 위반되는 내용의 게시물에 해당하는 경우</div>
          <div>3. 범죄 행위에 결부된다고 인정되는 내용인 경우</div>
          <div>4. 회사 또는 제3자의 저작권, 기타 타인의 권리를 침해하는 내용인 경우</div>
          <div>5. 회사가 정한 개별 서비스 별 세부이용지침에 반하는 내용인 경우</div>
          <div>6. 회사에서 제공하는 서비스와 관련 없는 내용인 경우</div>
          <div>7. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</div>
          <div>
            8. 타인의 명의 등을 무단으로 도용하여 작성한 내용이거나, 타인이 입력한 정보를 무단으로
            위ㆍ변조한 내용인 경우
          </div>
          <div>9. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우</div>
          <div>
            10. 게시물의 정보를 외부 서비스에서 사용하는 행위를 금지하는 사이트에서 URL 정보를
            수집하여 게재하는 경우
          </div>
          <div>
            11. 기타 관계 법령 및 회사의 개별 서비스 별 세부이용지침 등에 위반된다고 판단되는 경우
          </div>
        </section>
        <li>
          ② 회사는 개별 서비스 별로 게시물과 관련된 세부이용지침을 별도로 정하여 시행할 수 있으며,
          회원은 그 지침에 따라 게시물(회원간 전달 포함)을 게재하여야 합니다.
        </li>
        <li>
          ③ 판매자가 아니면서 공간공유(스터디카페, 스터디룸 등으로 한정하지 않음) 관련 직종에
          종사하는 회원이 해당 회원의 서비스와 제품에 대한 정보가 담긴 게시물을 서비스 내에 게재할
          경우에는 회사는 해당 게시물을 사전 통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떤
          책임도 지지 않습니다.
        </li>
      </ul>
      <p>제12조 (게시물의 저작권 등)</p>
      <ul>
        <li>
          ① 게시물의 저작권은 회원에게 있습니다. 다만 회사는 게시∙전달∙공유 목적으로 회원이 작성한
          게시물을 이용∙편집∙수정하여 이용할 수 있고, 회사의 다른 서비스 또는 연동채널∙판매채널에
          이를 게재하거나 활용할 수 있습니다.
        </li>
        <li>
          ② 회사가 서비스 내 게재 이외의 목적으로 게시물을 사용할 경우 게시물에 대한 게시자를 반드시
          명시해야 합니다. 단, 게시자를 알 수 없는 익명 게시물이나 비영리적인 경우에는 그러하지
          아니합니다.
        </li>
        <li>
          ③ 회원은 게시물을 작성할 때 타인의 저작권 등 지식재산권을 포함하여 여타 권리를 침해하지
          않아야 하고, 회사는 그에 대한 어떠한 책임도 부담하지 않습니다. 만일 회원이 타인의 권리
          등을 침해하였음을 이유로 타인이 회사를 상대로 이의신청, 손해배상청구, 삭제요청 등을 제기한
          경우 회사는 그에 필요한 조치를 취할 수 있으며, 그에 따른 모든 비용이나 손해배상책임은
          회원이 부담합니다.
        </li>
        <li>
          ④ 회사는 회원이 이용계약을 해지하고 사이트를 탈퇴하거나 적법한 사유로 해지된 경우 해당
          회원이 게시하였던 게시물을 삭제할 수 있습니다.
        </li>
        <li>
          ⑤ 회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, 회사는
          회원이 게시하거나 등록한 게시물이 다음 각 호에 해당한다고 판단되는 경우에 사전통지 없이
          삭제 또는 임시조치할 수 있고, 이에 대하여 회사는 어떠한 책임도 지지 않습니다.
        </li>
        <section>
          <div>(1) 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우</div>
          <div>(2) 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</div>
          <div>(3) 불법복제 또는 해킹을 조장하는 내용인 경우</div>
          <div>(4) 회사로부터 사전 승인 받지 않고 상업광고, 판촉 내용을 게시하는 경우</div>
          <div>(5) 개인 간 금전거래를 요하는 경우</div>
          <div>(6) 범죄적 행위에 결부된다고 인정되는 경우</div>
          <div>(7) 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</div>
          <div>
            (8) 타인의 계정정보, 성명 등을 무단으로 도용하여 작성한 내용이거나, 타인이 입력한 정보를
            무단으로 위변조한 내용인 경우
          </div>
          <div>
            (9) 사적인 정치적 판단이나 종교적 견해의 내용으로 회사가 서비스 성격에 부합하지 않는다고
            판단하는 경우
          </div>
          <div>(10) 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우</div>
          <div>
            (11) 회사에서 규정한 게시물 원칙에 어긋나거나, 게시물을 작성하는 위치에 부여된 성격에
            부합하지 않는 경우
          </div>
          <div>
            (12) 사업주 변경 또는 인테리어 공사 등에 따른 권리자(사업주)의 게시물 중단 또는 삭제
            요청이 있는 경우
          </div>
          <div>(13) 기타 관계법령에 위배된다고 판단되는 경우</div>
        </section>
        <li>
          ⑥ 회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우,
          권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며,
          회사는 관련법에 따라 조치를 취하여야 합니다.
        </li>
        <li>
          ⑦ 회사는 본조 제2항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가
          있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당
          &apos;게시물&apos;에 대해 임시조치 등을 취할 수 있습니다.
        </li>
        <li>
          ⑧ 본 조에 따른 세부절차는 정보통신망법 및 저작권법이 규정한 범위 내에서 회사가 정한
          게시중단요청서비스에 따릅니다. - 게시중단요청 : gosca.webmaster@gmail.com
        </li>
      </ul>
      <p>제13조 (회사의 의무)</p>
      <ul>
        <li>
          ① 회사는 서비스 제공과 관련하여 알고 있는 회원의 회원정보를 본인의 동의 없이 제3자에게
          제공하지 않습니다.
        </li>
        <li>
          ② 회사는 회원의 회원정보를 보호하기 위해 보안시스템을 구축 운영하며,
          &quot;개인정보취급방침&quot;을 공지하고 준수합니다. 또한, 회사는
          &quot;개인정보취급방침&quot;에 따라 회원정보를 취급함에 있어 안정성 확보에 필요한 기술적
          및 관리적 대책을 수립ㆍ운영합니다.
        </li>
        <li>
          ③ 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며,
          신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 E-mail 등을
          통하여 회원에게 통지합니다.
        </li>
        <li>
          ④ 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나
          중과실에 의해 발생한 경우에 한하여 회사에서 책임을 부담하며, 그 책임의 범위는 통상손해에
          한합니다.
        </li>
      </ul>
      <p>제14조 (회원의 의무)</p>
      <ul>
        <li>
          ① 회원은 관계법령, 약관, 서비스 이용안내 및 서비스상에 공지한 주의사항, 회사가 서비스
          이용과 관련하여 회원에게 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는
          행위를 하여서는 아니 됩니다.
        </li>
        <li>
          ② 회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 상품을 판매하는
          영업 활동을 할 수 없으며, 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위,
          상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및
          손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않으며, 회원은
          이와 같은 행위와 관련하여 회사에 대하여 손해배상 의무를 집니다.
        </li>
        <li>
          ③ 회원은 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도, 증여할 수
          없으며 이를 담보로 제공할 수 없습니다.
        </li>
      </ul>
      <p>제15조 (회원에 대한 통지)</p>
      <ul>
        <li>
          ① 회사는 회원의 서비스 이용에 필요한 권리 및 의무 등에 관한 사항을 회원이 지정한 E-mail,
          SMS 등으로 통지할 수 있습니다.
        </li>
        <li>
          ② 회사는 불특정 다수 회원에 대한 통지의 경우 서비스에 게시함으로써 개별 통지에 갈음할 수
          있습니다.
        </li>
      </ul>
      제16조 (ID와 PASSWORD 관리에 대한 의무와 책임)
      <ul>
        <li>
          ① 회원은 자신의 ID와 PASSWORD 관리를 철저히 하여야 합니다. ID와 PASSWORD의 관리 소홀, 부정
          사용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.
        </li>
        <li>
          ② 회사&apos;는 회원 ID의 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및
          회사의 운영자로 오인할 우려가 있는 경우, 해당 ID의 이용을 제한할 수 있습니다.
        </li>
        <li>
          ③ 회원은 본인의 ID 및 PASSWORD를 타인에게 이용하게 해서는 안되며, 회원 본인의 ID 및
          PASSWORD를 도난 당하거나 타인이 사용하고 있음을 인지하는 경우에는 바로 회사에 통보하고
          회사의 안내가 있는 경우 그에 따라야 합니다.
        </li>
      </ul>
      제17조 (회원정보의 보호 및 이용)
      <ul>
        <li>
          ① 회사는 적법하고 공정한 수단에 의하여 이용계약의 성립 및 이행에 필요한 최소한의 범위
          내에서 개인정보취급방침에 따라 회원의 회원정보를 수집합니다.
        </li>
        <li>
          ② 회사의 개인정보취급방침은 회사에 연동된 다른 서비스(회사가 운영하지 않는 서비스를
          말합니다. 이하 같습니다)에는 적용되지 않습니다.
        </li>
        <li>
          ③ 회사는 통신비밀보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 따라
          행정기관이나 수사기관 등에서 회원의 회원정보의 열람이나 제출을 요청하는 경우에는 이를
          제공할 수 있습니다.
        </li>
        <li>
          ④ 회사는 회원의 귀책사유로 인해 노출된 회원정보에 대해서 일체의 책임을 부담하지 않습니다.
        </li>
        <li>
          {' '}
          ⑤ 회사는 회원정보의 취급 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우
          이러한 업무의 일부 또는 전부를 회사가 선정한 회사에 위탁할 수 있으며, 회원정보의 취급 및
          관리 등에 관한 업무를 위탁하는 경우에는 개인정보취급방침을 통하여 공지합니다.
        </li>
        <li>
          ⑥ 회원이 이용계약 체결과정에서 회사에 제공한 회원정보에 대한 이용 또는 제공에 대한 동의를
          철회하고자 하는 경우에는 개인정보취급방침에서 정한 바에 따르며, 회원이 이러한 동의를
          철회하는 경우 서비스 이용계약은 자동으로 해지됩니다.
        </li>
      </ul>
      <p>제18조 (계약해지 및 이용제한)</p>
      <ul>
        <li>
          ① 회원이 서비스 이용계약을 해지하고자 할 경우에는 서비스를 제공하는 플랫폼에서 해지 신청을
          하여야 합니다. 홈페이지나 어플리케이션을 통해 계약 해지가 불가능한 경우에는 회원이 회사가
          제공하는 서비스 페이지 또는 전자우편 등의 방법으로 회사에 중지신청을 할 수 있습니다.
          회사는 이와 같은 요청을 받았을 경우, 회사가 별도로 고지한 방법에 따라 신속하게 처리합니다.
        </li>
        <li>
          ② 회사는 회원이 본 약관 및 개별서비스 이용약관을 위반한 경우 및 서비스의 정상적인 운영을
          방해한 경우에는 경고, 일시적 이용정지, 영구적 이용정지 등의 단계로 서비스 이용을
          제한하거나 이용계약을 해지할 수 있습니다.
        </li>
        <li>
          ③ 회원은 본 조 제 3항에 따른 서비스 이용정지 기타 서비스 이용과 관련된 이용제한에 대해
          관리자에게 E-mail을 통해 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고
          판단되는 경우 즉시 서비스 이용을 재개합니다.
        </li>
        <li>
          ④ 회사는 회원이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의
          효율성을 위해 이용을 제한할 수 있습니다.
        </li>
      </ul>
      <p>제 19조 (지급방법)</p>
      <ul>
        <li>
          ① 회사에서 구매한 재화 또는 서비스에 대한 대금지급방법은 다음 각 호의 방법 중 가용한
          방법으로 할 수 있습니다.
        </li>
        <section>
          <div>1. 폰뱅킹, 인터넷뱅킹 등의 각종 계좌이체</div>
          <div>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</div>
          <div>3. 온라인 가상계좌 입금</div>
          <div>4. 휴대폰 결제</div>
          <div>5. 할인이용권 등 회사가 지급한 이용권에 의한 결제</div>
          <div>6. 기타 회사가 추가 지정하는 결제 수단</div>
          <div>7. 결제수단은 회사의 상황에 따라 변경될 수 있습니다.</div>
        </section>
        <li>
          ② 회사는 회원의 대금지급에 법적, 기술적 문제가 발생하거나 회사가 예견하지 못한 장애(은행
          통신망 장애 등)가 발생하는 경우 회사의 정책에 따라 회원에게 결제 수단의 변경을 요청하거나
          잠정 결제보류 내지 거부할 수 있습니다.
        </li>
        <li>
          ③ 구매대금의 결제와 관련하여 회원이 입력한 정보 및 그와 관련된 책임은 회원에게 있으며,
          재화 또는 용역의 청약 이후 합리적인 일정 기간 내 결제가 이루어 지지 않는 경우 회사는
          해당주문을 취소할 수 있습니다.
        </li>
        <li>
          ④ 회사는 구매자의 결제수단에 대하여 정당한 사용권한 보유여부를 확인할 수 있으며 필요한
          경우 해당 거래진행의 보류 및 소명자료의 제출을 요청할 수 있습니다.
        </li>
      </ul>
      <p>제 20조 (환급)</p>
      <ul>
        <li>
          ① 회사는 이용자가 구매 신청한 재화 및 서비스에 대해 품절 등의 어느 사유로 인도 또는 제공을
          할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은
          경우에는 대금을 받은 날부터 3 영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
        </li>
      </ul>
      <p>제 21조 (청약철회 등)</p>
      <ul>
        <li>
          ① 회사와 재화 등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날로부터 7일
          이내에는 청약의 철회를 할 수 있습니다. 다만, 통지를 받은 때보다 공급이 늦게 이루어진
          경우에는 재화 등의 공급을 받은 날로부터 7일 이내에 청약 철회를 할 수 있습니다.
        </li>
        <li>
          ② 이용자는 재화 및 서비스 등을 구매한 경우 다음 각 호의 어느 하나에 해당하는 경우에는
          환불을 할 수 없습니다.
        </li>
        <section>
          <div>
            1. 이용자에게 책임이 있는 사유로 재화 등이 멸실 또는 훼손된 경우. (퇴실 미처리 등)
          </div>
          <div>2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</div>
          <div>3. 각 판매자에 의해 관리되는 환불규정에 따라 환불정책이 진행되는 경우</div>
        </section>
      </ul>
      <p>제 22조 (청약철회 등의 효과)</p>
      <ul>
        <li>
          ① 회사는 이용자로부터 재화 등을 반환 받은 경우 3 영업일 이내에 이미 지급받은 재화 등의
          대금을 환급합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에
          대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
        </li>
        <li>
          ② 회사는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화
          등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의
          대금의 청구를 정지 또는 취소하도록 요청합니다.
        </li>
        <li>
          ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 회사는
          이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의
          내용이 표시, 광고의 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우
          재화 등의 반환에 필요한 비용은 회사가 부담합니다.
        </li>
      </ul>
      <p>제 23조 (이용권)</p>
      <ul>
        <li>
          ① 회사는 재화 등을 구매하는 이용자에게 지정된 상품 구매 시 일정액 또는 일정비율을 할인
          받을 수 있는 이용권을 발급할 수 있습니다.
        </li>
        <li>
          ② 회사에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 어떠한 경우에도 이를
          타인에게 실질적으로 매매할 수 없습니다.
        </li>
        <li>
          ③ 상품이용권은 현금으로 출금될 수 없으며, 표시된 유효기간이 만료되거나 이용계약이 종료되면
          소멸합니다.
        </li>
        <li>
          ④ 상품이용권은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 회원을 탈퇴한 경우
          상품이용권은 소멸됩니다.
        </li>
      </ul>
      <p>제 24조 (손해배상)</p>
      <ul>
        <li>
          ① 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 본 약관을
          위반한 회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.
        </li>
        <li>
          ② 회원이 서비스를 이용하는 과정에서 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해
          회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해
          회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해
          회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
        </li>
      </ul>
      <p>제 25조 (면책사항)</p>
      <ul>
        <li>
          ① 회사는 천재지변, 디도스(DDOS)공격, IDC장애, 기간통신사업자의 회선 장애 또는 이에 준하는
          불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면책됩니다.
        </li>
        <li>② 회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.</li>
        <li>
          ③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그
          밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는
          회원이 사이트에 게재한 게시물의 정확성 등 내용에 대하여는 책임을 지지 않습니다.
        </li>
        <li>
          ④ 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는
          개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
        </li>
        <li>
          ⑤ 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는
          일절 책임을 지지 않습니다.
        </li>
        <li>
          ⑥ 게시물 또는 댓글 등을 통해 표현하거나 드러나는 회원의 견해와 회사의 견해는 무관하며,
          회사는 회원이 제공하는 게시물 또는 댓글 등에 대하여 어떠한 책임도 부담하지 아니합니다.
        </li>
        <li>
          ⑦ 본 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사(이하 피연결회사)와 회원간에
          이루어진 거래에 회사는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다.
        </li>
        <li>
          ⑧ 회사는 회원의 게시물을 등록 전에 사전심사 하거나 상시적으로 게시물의 내용을 확인 또는
          검토하여야 할 의무가 없으며, 그 결과에 대한 책임을 지지 않습니다.
        </li>
        <li>
          ⑨ 회사는 회원의 컴퓨터 환경이나 회사의 관리 범위에 있지 아니한 보안 문제로 인하여 발생하는
          제반 문제 또는 현재의 보안기술 수준으로 방어가 곤란한 네트워크 해킹 등 회사의 귀책사유
          없이 발생하는 문제에 대해서 책임을 지지 않습니다.
        </li>
      </ul>
      <p>제 26조 (분쟁 조정 및 관할법원)</p>
      <ul>
        <li>
          ① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의
          해결을 위해 성실히 협의합니다.
        </li>
        <li>
          ② 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 민사소송법상의 관할법원에
          소를 제기할 수 있습니다.
        </li>
        <li>③ 회사와 이용자간에 제기된 소송에는 대한민국 법을 적용합니다.</li>
        <li>
          ④ 본 약관은 한글로 작성되었습니다. 본 약관의 번역본이 한글 원문과 차이가 있을 경우 한글
          원문이 우선합니다.
        </li>
        <li>
          ⑤ 본 조 제1항의 협의에서도 분쟁이 해결되지 않아 소송이 제기되는 경우에는 “회사”의 본사
          소재지를 관할하는 법원을 전속 관할법원으로 합니다.
        </li>
        <li className="sub_text">부칙 (시행일) 본 약관은 2019년 9월 1일부터 시행합니다.</li>
      </ul>
    </>
  );
};

export default TypeTwoText;
