import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CHARGEBOARD } from '@components/chargeBoard/queryKey/queryKey';
import { getChargeBoardPerYear } from '@apis/chargeBoard';
import { useEffect, useState } from 'react';
import { ICharge } from '@dataTypes/chargeBoardTypes';

export const useChargeBoardLists = (year: number, storeId: number) => {
  const [getChargeBoardListsLoading, setGetChargeBoardListsLoading] = useState(true);

  const {
    data: getChargeBoardListsData,
    isLoading,
    isFetching,
  } = useQuery<ICharge[]>([CHARGEBOARD.CHARGEBOARDLISTS, year, storeId], () =>
    getChargeBoardPerYear(year, storeId),
  );

  useEffect(() => {
    setTimeout(() => {
      if (isLoading || isFetching) {
        setGetChargeBoardListsLoading(true);
      } else {
        setGetChargeBoardListsLoading(false);
      }
    }, 1000);
  }, [year, isLoading, isFetching]);

  return {
    setGetChargeBoardListsLoading,
    getChargeBoardListsData,
    getChargeBoardListsLoading,
  };
};
