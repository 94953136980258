import ChargeBoardContainer from '@components/chargeBoard/ChargeBoardContainer';
import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';
import React from 'react';

const ChargeBoard = () => {
  return (
    <div id="charge_board_container">
      <DashBoardHeader />
      <ChargeBoardContainer />
    </div>
  );
};

export default ChargeBoard;
