import { instance } from './index';
import { insertMachineData, modifyMachineData } from '@dataTypes/perMachineTypes';

export const getPerMachineLists = async (storeId: number | undefined) => {
  const response = await instance.get(`/user/device/${storeId}`);
  return response.data;
};

export const insertMachine = async (data: insertMachineData) => {
  const response = await instance.post(`/user/device/enrollment`, data);
  return response.data;
};

export const getPerMachineTotalSale = async (storeId: number | undefined, date: string) => {
  if (storeId === undefined) {
    return;
  }
  const response = await instance.get(`/user/sales-id/${storeId}/${date}`);
  return response.data;
};

export const modifyMachine = async (data: modifyMachineData) => {
  const response = await instance.patch(`/user/device/update-alias`, data);
  return response.data;
};
