import { grantUserRequestData, searchStoreUserRequestData } from '@dataTypes/grantAuthority';
import { instance } from './index';

export const getStoreUserListsByStoreID = async (storeId: number | undefined) => {
  const response = await instance.get(`/user/store-authority/${storeId}`);
  return response.data;
};

export const grantUser = async (data: grantUserRequestData) => {
  if (data.userId === undefined || data.storeId === undefined) {
    console.error('grantUser data error');
    return;
  }
  const response = await instance.post(`/user/store/grant-authority`, data);
  return response.data;
};

export const searchStoreUserList = async (data: searchStoreUserRequestData | null) => {
  if (data === null) {
    return console.error('searchStoreUserList request data null');
  }

  const params = {
    store: data.store,
    page: data.page,
    'phone-number': data['phone-number'],
    'login-id': data['login-id'] ? data['login-id'] : '',
  };
  const response = await instance.get('/user/user-list', { params });
  return response.data;
};
