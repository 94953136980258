import { ISignUpRequest, ISignUpResponse } from '@dataTypes/signTypes';
import { instance } from './index';

export const postEnroll = async (data: ISignUpRequest): Promise<ISignUpResponse> => {
  const response = await instance.post('/signup', data);
  return response.data;
};

export const postDuplication = async (id: string) => {
  const params = { 'login-id': id };
  const response = await instance.get('/check/login-duplicate', { params });
  return response.data;
};

export const sendSMS = async (phoneNumber: string): Promise<boolean> => {
  const response = await instance.post('/signup/send/phone-auth', { phoneNumber });
  return response.data;
};

export const checkSMS = async (authCode: string, phoneNumber: string): Promise<boolean> => {
  const data = {
    phoneNumber: phoneNumber,
    authCode: authCode,
  };
  const response = await instance.patch('/signup/verify/phone-auth', data);
  return response.data;
};
