import {
  IBulbDeviceState,
  IControlPlugAirconditionerRequest,
  IControlPlugMiniRequest,
  IDevice,
} from '@dataTypes/hejHomeTypes';
import { instance } from './index';

// export const getHejHomeToken = async (clientId: string) => {
//   const response = await instance.get(`/oauth/token`);
//   return response.data;
// };

export const getDeviceList = async (): Promise<IDevice[]> => {
  const response = await instance.get(`/openapi/devices`);
  return response.data;
};

export const getDeviceStateList = async () => {
  const response = await instance.get(`/openapi/devices/state`);
  return response.data;
};

// export const getDeviceControlList = async (deviceId: string) => {
//   const data = {
//     requirments: {
//       power: true,
//       temperature: 20,
//       fanSpeed: '0',
//       mode: '1',
//     }
//   };
//   const response = await instance.post(`/openapi/control/${deviceId}`, data);
//   return response.data;
// };

export const getTemperature = async (deviceId: string) => {
  const response = await instance.get(`/openapi/device/TH/${deviceId}`);
  return response.data;
};

export const stateDevice = async (deviceId: string) => {
  const response = await instance.get(`/openapi/device/${deviceId}`);
  return response.data;
};

//디바이스 제어는 return값이 없어서 response.code로 상태 확인
export const controlDevice = async (
  deviceId: string,
  data: IControlPlugMiniRequest | IControlPlugAirconditionerRequest | IBulbDeviceState,
) => {
  const response = await instance.post(`/openapi/control/${deviceId}`, { requirments: data });
  return response;
};
