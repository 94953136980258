import CommonHeader from '@components/common/CommonHeader';
import DeleteAccountMain from '@components/deleteAccount/DeleteAccountMain';
import React from 'react';

const DeleteAccount = () => {
  return (
    <div id="delete_account_container">
      <CommonHeader>회원탈퇴</CommonHeader>
      <DeleteAccountMain />
    </div>
  );
};

export default DeleteAccount;
