import { IControlPlugMiniRequest, IDevice, IPlugMiniState } from '@dataTypes/hejHomeTypes';
import { useEffect, useState } from 'react';
import { IDeviceProps } from './InternetOfThingsMain';
import { stateDevice, controlDevice } from '../../hejHomeApis/deviceList';
import { instance } from '../../hejHomeApis/index';
import onOffIcon from '@assets/icon/on_off_icon(black).png';
import mainIcon from '@assets/icon/plug_main_icon.png';
import onOffIconBlack from '@assets/icon/on_off_icon(black).png';
import onOffIconWhite from '@assets/icon/on_off_icon(white).png';

const PlugMini = ({ list }: IDeviceProps) => {
  const [plugMiniState, setPlugMiniState] = useState<IPlugMiniState>();

  useEffect(() => {
    stateDevice(list.id).then((list: IPlugMiniState) => setPlugMiniState(list));
  }, []);

  const onOffHandler = async () => {
    const accessToken = instance.defaults.headers['Authorization'];
    console.log('accessToken: ', accessToken);

    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!list.online) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (plugMiniState && plugMiniState.id) {
      const requirments: IControlPlugMiniRequest = {
        power: !plugMiniState?.deviceState.power,
        // powerStatus: plugMiniState.deviceState.powerStatus,
        // ledlightMode: plugMiniState.deviceState.ledlightMode,
        // childLock: false,
      };

      const result = await controlDevice(plugMiniState?.id, requirments);

      if (result.status === 200) {
        setPlugMiniState((prevState) => {
          if (!prevState) return prevState; // 상태가 undefined일 경우 처리
          return {
            ...prevState,
            deviceState: {
              ...prevState.deviceState,
              power: !prevState.deviceState.power,
            },
          };
        });
      }
    }
  };

  const lightOnOffHandler = async () => {
    const accessToken = instance.defaults.headers['Authorization'];

    if (!accessToken) {
      console.error('no accessToken');
      return;
    }
    if (plugMiniState && plugMiniState.id) {
      const requirments: IControlPlugMiniRequest = {
        // power: !plugMiniState?.deviceState.power,
        // powerStatus: plugMiniState.deviceState.powerStatus,
        ledlightMode: 'led_switch',
        // childLock: false,
      };

      const result = await controlDevice(plugMiniState?.id, requirments);

      if (result.status === 200) {
        setPlugMiniState((prevState) => {
          if (!prevState) return prevState; // 상태가 undefined일 경우 처리
          return {
            ...prevState,
            deviceState: {
              ...prevState.deviceState,
              ledlightMode: 'led_off',
            },
          };
        });
      }
    }
  };

  return (
    <>
      {/* <div>{list.name}</div>
        <div>{list.deviceType}</div>
        <div>{list.modelName}</div>
        <div>{list.category}</div>
        <div>네트워크 {list.online ? '켜짐' : '꺼짐'}</div>
        <br /> */}
      <li className="device_list">
        <div className="top_wrapper">
          <img className="main_icon" alt="콘센트 아이콘" src={mainIcon} />
          <div className="title">{list.name}</div>

          {/* <div className="go_arrow">
            <FaChevronRight />
          </div> */}
        </div>
        <div
          className={`on_off_btn_wrapper ${plugMiniState?.deviceState.power === true && list.online ? 'on' : 'off'}`}
          onClick={onOffHandler}
        >
          <img
            className="button_icon"
            src={
              plugMiniState?.deviceState.power === true && list.online
                ? onOffIconBlack
                : onOffIconBlack
            }
            alt="전원 버튼"
          />
          <span>전원</span>
        </div>
        {/* <div>{list.online ? '켜짐' : '꺼짐'}</div> */}
      </li>

      {/* <button onClick={onOffHandler}>온/오프</button>
      <button onClick={lightOnOffHandler}>불빛 온/오프</button>
      <button></button> */}
    </>
  );
};

export default PlugMini;
