import React from 'react';
import { convertMoney } from '../../services/common/convertMoney';

interface ChargeItemProps {
  month: string;
  card?: number;
  fee?: number;
  cardDepositAmount?: number;
}

const ChargeItem = ({ card, cardDepositAmount, fee, month }: ChargeItemProps) => {
  const formattedMonth = month.substring(4);

  return (
    <li className="item">
      <span className="month">{formattedMonth}月</span>
      <div className="money_wrapper">
        <span className="card_custom text">{card ? convertMoney(card) : 0}원</span>
        <span className="cardDepositAmount text">
          {cardDepositAmount ? convertMoney(cardDepositAmount) : 0}원
        </span>
        <span className="fee text">{fee ? convertMoney(fee) : 0}원</span>
      </div>
    </li>
  );
};

export default ChargeItem;
