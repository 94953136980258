import { modifyMachine } from '@apis/perMachine';
import { PERMACHINE } from '@components/perMachine/queryKey/queryKey';
import { insertMachineData, modifyMachineData } from '@dataTypes/perMachineTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useModifyMachine = () => {
  const queryClient = useQueryClient();

  return useMutation((data: modifyMachineData) => modifyMachine(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([PERMACHINE.MACHINELISTS]);
    },
  });
};
