import AddStoreContainer from '@components/AddStore/AddStoreContainer';
import CommonHeader from '@components/common/CommonHeader';
import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';
import React from 'react';

const AddStore = () => {
  return (
    <div id="add_store_container">
      <CommonHeader>지점 등록</CommonHeader>
      <AddStoreContainer />
    </div>
  );
};

export default AddStore;
