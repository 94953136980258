import { instance } from './index';

export const getCheckIOT = async () => {
  const response = await instance.get('/user/store/check-iot');
  return response.data;
};

export const addIOT = async (code: string) => {
  const data = { code };
  const response = await instance.post('/user/store/iot/add', data);
  return response.data;
};
