import { IBulbDeviceState, ZigbeeSwitchProps } from '@dataTypes/hejHomeTypes';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { controlDevice } from '../../hejHomeApis/deviceList';
import offIcon from '@assets/icon/on_off_icon(black).png';
import onIcon from '@assets/icon/on_off_icon(color).png';
import { instance } from '../../hejHomeApis/index';

interface SwitchThreeBulbControlModalProps {
  bulbState: ZigbeeSwitchProps | undefined;
  setBulbState: React.Dispatch<React.SetStateAction<ZigbeeSwitchProps | undefined>>;
  show: boolean;
  closeModal: () => void;
  name: string;
  deviceOnline: boolean;
}

const SwitchThreeBulbControlModal = ({
  bulbState,
  closeModal,
  name,
  show,
  deviceOnline,
  setBulbState,
}: SwitchThreeBulbControlModalProps) => {
  const accessToken = instance.defaults.headers['Authorization'];

  const handleClick = async (switchNumber: 'power1' | 'power2' | 'power3') => {
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!deviceOnline) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (bulbState && bulbState.id) {
      const updatedState = {
        ...bulbState.deviceState,
        [switchNumber]: !bulbState.deviceState[switchNumber],
      };

      const result = await controlDevice(bulbState.id, updatedState);

      if (result.status === 200) {
        setBulbState((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            deviceState: updatedState,
          };
        });
      }
    }
  };

  const allOnOff =
    bulbState?.deviceState.power1 && bulbState?.deviceState.power2 && bulbState?.deviceState.power3;

  const allOnOffHandler = async () => {
    if (!accessToken) {
      console.error('no accessToken');
      return;
    }

    if (!deviceOnline) {
      alert('기기 연결 오프라인 상태입니다.');
      return;
    }

    if (bulbState && bulbState.id) {
      const allOn =
        !bulbState.deviceState.power1 ||
        !bulbState.deviceState.power2 ||
        !bulbState.deviceState.power3;
      const updatedState = {
        power1: allOn,
        power2: allOn,
        power3: allOn,
      };

      const result = await controlDevice(bulbState.id, updatedState);

      if (result.status === 200) {
        setBulbState((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            deviceState: updatedState,
          };
        });
      }
    }
  };

  return (
    <Offcanvas id="bulb_control_modal_wrapper" show={show} onHide={closeModal} placement="bottom">
      <Offcanvas.Header closeButton className="modify_modal_header">
        <Offcanvas.Title>{name}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bulb_modal_body">
        <div className="switch_wrapper">
          <div
            className={`switch ${bulbState?.deviceState.power1 ? 'on' : 'off'}`}
            onClick={() => handleClick('power1')}
          >
            <div className="text">전구 1</div>
            <img alt="버튼 1 스위치" src={`${bulbState?.deviceState.power1 ? onIcon : offIcon}`} />
          </div>

          <div
            className={`switch ${bulbState?.deviceState.power2 ? 'on' : 'off'}`}
            onClick={() => handleClick('power2')}
          >
            <div className="text">전구 2</div>
            <img alt="버튼 2 스위치" src={`${bulbState?.deviceState.power2 ? onIcon : offIcon}`} />
          </div>

          <div
            className={`switch ${bulbState?.deviceState.power3 ? 'on' : 'off'}`}
            onClick={() => handleClick('power3')}
          >
            <div className="text">전구 3</div>
            <img alt="버튼 3 스위치" src={`${bulbState?.deviceState.power3 ? onIcon : offIcon}`} />
          </div>
        </div>

        <div className={`on_off_btn_wrapper ${allOnOff ? 'on' : 'off'}`} onClick={allOnOffHandler}>
          <span>{allOnOff ? '전체 끄기' : '전체 켜기'}</span>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SwitchThreeBulbControlModal;
