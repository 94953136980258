import React, { useEffect, useState } from 'react';
import UserListNav from './UserListNav';
import MainHeader from './MainHeader';
import AddMemberWithAuthTypeModal from './AddMemberWithAuthTypeModal';
import { useParams } from 'react-router-dom';
import { useGetGrantListAuthorityByStoreID } from '@hooks/grantAuthority/useGetGrantListAuthorityByStoreID';
import ShowConfirmMemberModal from './ShowConfirmMemberModal';
import { getSearchStoreUser, grantUserListData } from '@dataTypes/grantAuthority';
import { AUTH } from '@dataTypes/authTypes';
import Cookies from 'js-cookie';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';

const GrantAuthorityMain = () => {
  const storeInfoValue = useRecoilValue(storeInfo);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [clickedUser, setClickedUser] = useState<getSearchStoreUser>();
  //나중에 수정 때 사용하면 됨
  const [modifyUser, setModifyUser] = useState<grantUserListData>();
  const [inputSearchVal, setInputSearchVal] = useState('');

  const {
    userLists,
    getGrantListAuthorityByStoreIDData,
    getGrantListAuthorityByStoreIDFetching,
    getGrantListAuthorityByStoreIDLoading,
  } = useGetGrantListAuthorityByStoreID(storeInfoValue.currentStoreId);

  const [addMemberModal, setAddMemberModal] = useState(false);
  const closeAddMemberModal = () => {
    setAddMemberModal(false);
  };

  const showConfirmModalWithClickedUser = (user: getSearchStoreUser) => {
    setClickedUser(user);
    setShowConfirmModal(true);
  };

  const [navActive, setNavActive] = useState({
    total: true,
    manager: false,
    user: false,
  });

  const navActiveHandler = (nav: string) => {
    setNavActive((prevNavActive) => ({
      ...prevNavActive,
      [nav]: true,
      total: nav === 'total',
      manager: nav === 'manager',
      user: nav === 'user',
    }));
  };

  const getTrueKeys = (obj: { [key: string]: boolean }) => {
    return Object.entries(obj)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
  };

  const updatedUserListData = () => {
    const trueKeys = getTrueKeys(navActive);
    const searchValue = inputSearchVal.trim();

    const filterBySearch = (list: grantUserListData) =>
      list.phone_number.includes(searchValue) ||
      list.name.includes(searchValue) ||
      list.login_id.includes(searchValue);

    let updatedUserLists: grantUserListData[] = userLists;

    if (trueKeys.includes('manager')) {
      updatedUserLists = updatedUserLists.filter((list) => list.authority === AUTH.MANAGER);
    } else if (trueKeys.includes('user')) {
      updatedUserLists = updatedUserLists.filter((list) => list.authority === AUTH.USER);
    }

    if (searchValue.length > 0) {
      updatedUserLists = updatedUserLists.filter(filterBySearch);
    }

    return updatedUserLists;
  };

  return (
    <div className="grant_authority_main">
      <MainHeader
        setAddMemberModal={setAddMemberModal}
        inputSearchVal={inputSearchVal}
        setInputSearchVal={setInputSearchVal}
      />

      <UserListNav
        userLists={updatedUserListData()}
        setModifyUser={setModifyUser}
        showConfirmModal={() => setShowConfirmModal(true)}
        navActiveHandler={navActiveHandler}
      />
      <AddMemberWithAuthTypeModal
        addMemberModal={addMemberModal}
        showConfirmModal={showConfirmModalWithClickedUser}
        closeAddMemberModal={closeAddMemberModal}
      />
      <ShowConfirmMemberModal
        closeAddMemberModal={closeAddMemberModal}
        showConfirmModal={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        user={clickedUser}
      />
    </div>
  );
};

export default GrantAuthorityMain;
