import React from 'react';

const ChargeItemSkeleton = () => {
  return (
    <li className="item skeleton">
      <span className="month">1月</span>
      <div className="money_wrapper">
        <span className="card_custom text"> 0원</span>
        <span className="cardDepositAmount text">0원</span>
        <span className="fee text">0원</span>
      </div>
    </li>
  );
};

export default ChargeItemSkeleton;
