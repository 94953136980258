import React, { ChangeEvent, useState } from 'react';
import { insertMachineData, machineListsData, modifyMachineData } from 'types/perMachineTypes';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import modifyIcon from '@assets/icon/modify_icon.png';
import { useModifyMachine } from '../../hooks/perMachine/useModifyMachine';

interface MachineListItemProps {
  item: machineListsData;
}

const MachineListItem = ({ item }: MachineListItemProps) => {
  const { mutate } = useModifyMachine();

  const [tooltipActive, setTooltipActive] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const [inputVal, setInputVal] = useState<number | string>(item.deviceAlias);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(false);

    if (!tooltipActive) {
      setTooltipActive(true);
      setTimeout(() => {
        setTooltipActive(false);
      }, 300);
    }
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\D/g, '');
    setInputVal(value);
  };

  const handleModifyClick = () => {
    setShowModify(true);
    setShowTooltip(false);
  };

  const cancelHandler = () => {
    setShowModify(false);
    setInputVal(item.deviceAlias);
  };

  const confirmHandler = async () => {
    const data: modifyMachineData = {
      alias: inputVal.toString(),
      deviceId: item.deviceId,
    };
    console.log(data);
    mutate(data);
    setShowModify(false);
  };

  return (
    <div className="sales_item_wrapper">
      <div className="sales_date">
        <span className="title">{item.catId}</span>
        {!showModify ? (
          <span className="text">{item.deviceAlias}</span>
        ) : (
          <input
            type="text"
            className="input_text"
            value={inputVal}
            onChange={inputChangeHandler}
            autoFocus={true}
          />
        )}

        <OverlayTrigger
          trigger="click"
          placement={'bottom'}
          rootClose={true}
          rootCloseEvent="mousedown"
          show={showTooltip}
          onToggle={handleClick}
          overlay={
            <Popover id={`popover-positioned-bottom`} className="machine_tooltip">
              <Popover.Body>
                <div className="modify_wrapper" onClick={handleModifyClick}>
                  <img src={modifyIcon} alt="수정 아이콘" className="modify_icon" />
                  <div className="text">수정하기</div>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div
            className={`tooltip_icon ${tooltipActive ? 'active' : ''}`}
            onClick={() => setShowTooltip(!showTooltip)}
          >
            <BsThreeDotsVertical className="icon" />
          </div>
        </OverlayTrigger>
      </div>
      {showModify && (
        <div className="btn_wrapper">
          <Button className="cancel" onClick={cancelHandler}>
            취소
          </Button>
          <Button className="confirm" onClick={confirmHandler}>
            수정
          </Button>
        </div>
      )}
    </div>
  );
};

export default MachineListItem;
