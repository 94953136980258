import React, { useEffect, useState } from 'react';
import { IDeviceProps } from './InternetOfThingsMain';
import { stateDevice, controlDevice } from '../../hejHomeApis/deviceList';
import onOffIcon from '@assets/icon/on_off_icon(black).png';
import mainIcon from '@assets/icon/plug_main_icon.png';
import { ZigbeeSwitchProps } from '@dataTypes/hejHomeTypes';
import SwitchThreeBulbControlModal from './SwitchThreeBulbControlModal';

const SwitchThreeBulb = ({ list }: IDeviceProps) => {
  const [bulbState, setBulbState] = useState<ZigbeeSwitchProps>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    stateDevice(list.id).then((list: ZigbeeSwitchProps) => setBulbState(list));
  }, []);

  return (
    <>
      <li className="device_list" onClick={() => setShow(true)}>
        <div className="top_wrapper">
          <img className="main_icon" alt="콘센트 아이콘" src={mainIcon} />
          <div className="title">{list.name}</div>
        </div>
        <div className={`on_off_btn_wrapper ${list.online ? 'on' : 'off'}`}>
          <img
            className={`button_icon ${list.online ? 'on' : 'off'}`}
            src={onOffIcon}
            alt="전원 버튼"
          />
          <span>스위치 제어</span>
        </div>
      </li>

      <SwitchThreeBulbControlModal
        bulbState={bulbState}
        setBulbState={setBulbState}
        show={show}
        closeModal={() => setShow(false)}
        name={list.name}
        deviceOnline={list.online}
      />
    </>
  );
};

export default SwitchThreeBulb;
