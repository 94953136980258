import React from 'react';

interface MonthTotalListsProps {
  totalMonth: number;
  card: number;
  cash: number;
  etc: number;
}

const MonthTotalLists = ({ card, cash, etc, totalMonth }: MonthTotalListsProps) => {
  return (
    <div className="month_total_lists_container">
      <div className="sales_wrapper purple">
        <span className="title">총 매출</span>
        <span className="money">{totalMonth.toLocaleString('ko-KR')}</span>
      </div>

      <div className="sales_wrapper red">
        <span className="title">신용카드</span>
        <span className="money">{card.toLocaleString('ko-KR')}</span>
      </div>

      <div className="sales_wrapper green">
        <span className="title">현금</span>
        <span className="money">{cash.toLocaleString('ko-KR')}</span>
      </div>

      <div className="sales_wrapper orange">
        <span className="title">신용기타</span>
        <span className="money">{etc}</span>
      </div>
    </div>
  );
};

export default MonthTotalLists;
