export interface ITotalSalesItem {
  id: number; // Long 타입은 number로 변환
  transactionStatus: TRANSACTION;
  method: PAYMETHOD;
  amount: number;
  tax: number;
  serviceCharge: number;
  installmentPeriod: string;
  approvalNum: string;
  catId: string;
  cardNum: string;
  businessNum: string;
  cardCompany: string;
  transactionDate: string;
  vanKey: string;
}

export interface ITotalSalesResponse {
  totalCount: number;
  totalSales: ITotalSalesItem[];
}

export enum TRANSACTION {
  A = 'A',
  C = 'C',
  E = 'E',
}

export enum PAYMETHOD {
  TOTAL = '',
  CARD = 'CARD',
  CASH = 'CASH',
  ETC = 'ETC',
}

export interface datePickerProps {
  startDate: Date;
  endDate: Date;
  key: string;
}
