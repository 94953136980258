import SignUpUser from '@components/signUp/SignUpUser';
import React, { useState } from 'react';

const SignUp = () => {
  return (
    <div id="sign_up_container">
      <SignUpUser />
    </div>
  );
};

export default SignUp;
