import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import logo from '@assets/logo/smartLab_logo(black_horizontal).png';
import { useSetRecoilState } from 'recoil';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import storeInfo from '../../recoil/storeInfo/atom';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changePasswordRequest, deleteAccountRequest } from '@apis/deleteAccount';
import { imgTypes } from '@dataTypes/imgTypes';
import { AxiosHeaders } from 'axios';

const ChangePasswordMain = () => {
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [newInputValue, setNewInputValue] = useState('');
  const [checkNewInputValue, setCheckNewInputValue] = useState('');
  const setEndState = useSetRecoilState(errorOrYesModalState);
  const setCurrentStoreInfo = useSetRecoilState(storeInfo);
  const [checkPassword, setCheckPassword] = useState(false);

  const currentInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCurrentInputValue(value);
  };

  const newInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewInputValue(value);
  };

  const checkNewInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCheckNewInputValue(value);
  };

  const navigation = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: changePasswordAsync } = useMutation(
    () => changePasswordRequest(currentInputValue, newInputValue),
    {
      onSuccess: (data) => {
        if (data) {
          setEndState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '비밀번호 변경이 완료되었습니다.',
            text: '변경된 비밀번호로 재로그인 부탁드립니다.',
            modalBG: true,
            yesCallBack: goHomeHandler,
          });

          setCurrentStoreInfo({
            currentStore: '',
            currentAuthority: '',
            currentStoreId: undefined,
            storeLists: [],
          });

          // queryClient.invalidateQueries();
        }
      },
      onError: (data: AxiosHeaders) => {
        setEndState({
          status: true,
          imgType: imgTypes.ERROR,
          title: data.response.data.message,
          text: '비밀번호 또는 로그인 유지 확인부탁드립니다',
          modalBG: true,
        });
      },
    },
  );

  const goHomeHandler = () => {
    setTimeout(() => {
      navigation('/');
    }, 300);
  };

  const handleBlur = () => {
    if (newInputValue !== checkNewInputValue) {
      setCheckPassword(true);
    } else {
      setCheckPassword(false);
    }
  };

  const checkPasswordHandler = () => {
    if (
      !checkPassword &&
      newInputValue.length >= 5 &&
      newInputValue.length <= 30 &&
      currentInputValue.length >= 5 &&
      currentInputValue.length <= 30
    ) {
      changePasswordAsync();
    } else {
      setEndState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '비밀번호 값 오류입니다.',
        text: '비밀번호 길이 또는 변경하실 <br /> 비밀번호 값이 일치하는지 확인해주세요.',
        modalBG: true,
      });
    }
  };

  return (
    <div className="delete_account_main">
      <img src={logo} className="logo" alt="로고" />
      <div className="alert_text">비밀번호 변경 전 꼭 확인해주세요</div>
      <div className="info_wrapper">
        <div>기존 비밀번호를 새 비밀번호로 변경하실 수 있습니다.</div>
        <div>비밀번호는 5자 이상 30자 이하 입니다.</div>
      </div>

      <div className="input_form mb-2">
        <div className="input_wrapper">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              value={currentInputValue}
              type="password"
              id="current_pw"
              maxLength={25}
              className="input_section"
              placeholder="현재 비밀번호"
              onChange={currentInputHandler}
              autoComplete="off"
            />
          </Form>
        </div>
      </div>

      <div className="input_form">
        <div className="input_wrapper">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              value={newInputValue}
              type="password"
              id="new_pw"
              maxLength={25}
              className="input_section"
              placeholder="새 비밀번호"
              onChange={newInputHandler}
              autoComplete="off"
            />
          </Form>
        </div>
      </div>

      <div className="input_form mt-1">
        <div className="input_wrapper">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              value={checkNewInputValue}
              type="password"
              id="check_new_pw"
              maxLength={25}
              className="input_section"
              placeholder="새 비밀번호 확인"
              onChange={checkNewInputHandler}
              onBlur={handleBlur}
              autoComplete="off"
            />
          </Form>
        </div>

        {checkPassword && <div className="password_check_error">비밀번호가 일치하지 않습니다.</div>}
      </div>

      <Button className="confirm_btn" onClick={() => checkPasswordHandler()}>
        변경하기
      </Button>
    </div>
  );
};

export default ChangePasswordMain;
