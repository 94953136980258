import React from 'react';
import { Nav } from 'react-bootstrap';
import MemberItem from './MemberItem';
import { grantUserListData } from '@dataTypes/grantAuthority';

interface UserListNavProps {
  userLists: grantUserListData[];
  setModifyUser: React.Dispatch<React.SetStateAction<grantUserListData | undefined>>;
  showConfirmModal: () => void;
  navActiveHandler: (nav: string) => void;
}

const UserListNav = ({
  userLists,
  setModifyUser,
  showConfirmModal,
  navActiveHandler,
}: UserListNavProps) => {
  const showConfirmModalWithLoginId = (user: grantUserListData) => {
    setModifyUser(user);
    showConfirmModal();
  };

  return (
    <div id="member_management_container">
      <section className="nav_add_list_wrapper">
        <Nav
          fill
          variant="tabs"
          defaultActiveKey="link-1"
          className="member_type_nav_contents .user_list_nav_wrapper"
        >
          <Nav.Item onClick={() => navActiveHandler('total')} className="nav_custom_item">
            <Nav.Link eventKey="link-1" className="nav_section">
              <div className="type_text_wrapper">
                <span className="type">전체</span>
              </div>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => navActiveHandler('manager')} className="nav_custom_item">
            <Nav.Link eventKey="link-2" className="nav_section">
              <div className="type_text_wrapper">
                <span className="type">매니저</span>
              </div>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => navActiveHandler('user')} className="nav_custom_item">
            <Nav.Link eventKey="link-3" className="nav_section">
              <div className="type_text_wrapper">
                <span className="type">유저</span>
              </div>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </section>

      <section className="member_list_wrapper">
        <div className="item_wrapper header">
          <div className="name">이름</div>
          <div className="phone">휴대폰 번호</div>
          <div className="auth">권한</div>
        </div>

        {userLists.map((list) => (
          <MemberItem
            key={list.login_id + list.phone_number}
            loginId={list.login_id}
            name={list.name}
            phoneNumber={list.phone_number}
            auth={list.authority}
            showConfirmModalWithLoginId={showConfirmModalWithLoginId}
          />
        ))}
      </section>
    </div>
  );
};

export default UserListNav;
