import React from 'react';
import spinner from '../../../assets/gif/spnnier(gray).gif';
const Loading = () => {
  return (
    <div id="loading_container">
      <img src={spinner} alt="로딩바" className="loading_icon" />
    </div>
  );
};

export default Loading;
