import React, { useState } from 'react';
import InputId from './InputId';
import InputPhoneNumber from './InputPhoneNumber';
import FindThenChangePassword from './FindThenChangePassword';
import logo from '@assets/logo/smartLab_logo(black).png';

const FindPasswordContainer = () => {
  const [inputId, setInputId] = useState('');
  const [inputPhone, setInputPhone] = useState('');

  const inputIdHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputId(e.target.value);
  };

  const phoneInputHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 11);
    setInputPhone(sanitizedValue);
  };

  return (
    <div className="find_password_wrapper">
      <img src={logo} alt="로고" className="logo" />
      <InputId inputId={inputId} inputIdHandler={inputIdHandler} />
      <InputPhoneNumber inputPhone={inputPhone} phoneInputHandler={phoneInputHandler} />
      <FindThenChangePassword />
    </div>
  );
};

export default FindPasswordContainer;
