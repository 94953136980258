import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { AUTH } from '@dataTypes/authTypes';
import { Button, Form } from 'react-bootstrap';
import { IAddCCTVRequest, ICCTVItem } from '@dataTypes/cctvTypes';
import { addCCTV, getCCTVList } from '@apis/cctv';
import { useCCTVList } from '@hooks/cctv/useCCTVList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CCTV } from './queryKey/queryKey';
import { AxiosHeaders } from 'axios';

const CctvContainer = () => {
  const queryClient = useQueryClient();
  const currentUser = useRecoilValue(storeInfo);
  const [cctvName, setCctvName] = useState<string>('');
  const [cctvUser, setCctvUser] = useState<string>('');
  const [cctvPassword, setCctvPassword] = useState<string>('');
  const [ipAddress, setIpAddress] = useState<string>('');

  const { getCCTVListData, getCCTVListFetching, getCCTVListLoading } = useCCTVList(
    currentUser.currentStoreId,
  );

  const { mutateAsync: addCCTVAsync } = useMutation((data: IAddCCTVRequest) => addCCTV(data), {
    onSuccess: () => {
      alert('등록 성공');
      queryClient.invalidateQueries([CCTV.CCTVLIST]);
    },
    onError: (error: AxiosHeaders) => {
      alert(error.response.data.message);
    },
  });

  useEffect(() => {
    getCCTVListHandler();
  }, [getCCTVListData]);

  const getCCTVListHandler = async () => {
    if (
      currentUser &&
      currentUser.currentStoreId &&
      getCCTVListData &&
      getCCTVListData.length > 0
    ) {
      const flutterInAppWebView = window.flutter_inappwebview;
      if (flutterInAppWebView) {
        flutterInAppWebView.callHandler('cctvInfoHandler', getCCTVListData).then((result: any) => {
          console.log(JSON.stringify(result));
          // alert(JSON.stringify(result));
        });
      } else {
        console.error('flutter_inappwebview is not available on window');
      }
    }
  };

  const handleCctvNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCctvName(event.target.value);
  };

  const handleCctvUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCctvUser(event.target.value);
  };

  const handleCctvPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCctvPassword(event.target.value);
  };

  const handleIpAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIpAddress(event.target.value);
  };

  const addCCTVHandler = async () => {
    if (currentUser && currentUser.currentStoreId) {
      const data: IAddCCTVRequest = {
        cctvName: cctvName,
        cctvUser: cctvUser,
        cctvPassword: cctvPassword,
        ipAddress: ipAddress,
        store: currentUser.currentStoreId,
      };
      addCCTVAsync(data);
    }
  };

  return (
    <div className="cctv_container_wrapper">
      {/* {currentUser.currentAuthority !== AUTH.USER} */}

      <div className="main_text_wrapper">
        <h1 className="list_title">CCTV 모니터링</h1>
        <h2 className="list_sub_text">매니저가 CCTV 기기 등록 후 사용 가능합니다.</h2>
      </div>

      <Form.Group className="mb-2 mt-2" controlId="cctvName">
        <Form.Control
          className="form_text"
          type="text"
          placeholder="CCTV 이름"
          autoComplete="on"
          value={cctvName}
          onChange={handleCctvNameChange}
        />

        <Form.Control
          className="form_text"
          type="text"
          placeholder="cctv user"
          autoComplete="on"
          value={cctvUser}
          onChange={handleCctvUserChange}
        />

        <Form.Control
          className="form_text"
          type="password"
          placeholder="cctv password"
          autoComplete="on"
          value={cctvPassword}
          onChange={handleCctvPasswordChange}
        />

        <Form.Control
          className="form_text"
          type="text"
          placeholder="cctv ip address"
          autoComplete="on"
          value={ipAddress}
          onChange={handleIpAddressChange}
        />
      </Form.Group>
      <Button onClick={addCCTVHandler}>cctv 등록</Button>

      <ul>
        <li>cctv 목록</li>
        {getCCTVListData?.map((list, index) => (
          <li key={index}>
            <div>cctvName: {list.cctvName}</div>
            <div>cctvUser: {list.cctvPassword}</div>
            <div>cctvPassword: {list.cctvUser}</div>
            <div>ipAddress: {list.ipAddress}</div>
            <div>name: {list.name}</div>
            <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CctvContainer;
