import React, { useState } from 'react';
import { Button, Form, FormCheck } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';
import checkIcon from '@assets/icon/check.png';
/*
  @NotNull(message="지점 고유 ID는 빈 값이 올 수 없습니다.")
  @Positive(message = "지점 고유 ID는 양의 정수여야 합니다.")
  private Long storeId;

  @NotBlank(message="지점명은 빈 값이 올 수 없습니다.")
  private String storeName;

  @NotBlank(message="사업자 번호는 빈 값이 올 수 없습니다.")
  private String businessNum;

  @NotBlank(message="핸드폰 번호는 빈 값이 올 수 없습니다.")
  @Length(min=11, max=11, message = "전화번호는 11자리여야 합니다." )
  private String phoneNumber;

  @NotNull(message="아이디 생성 여부는 빈 값이 올 수 없습니다.")
  private boolean isMemberCheckBox;

  @Pattern(regexp="^[a-zA-Z0-9\\-]*$", message="아이디에는 특수 문자를 포함할 수 없습니다.")
  @Length(min=5, max = 25, message = "로그인 아이디는 최소 5자 이상 최대 25자까지 입력해야 합니다.")
  private String userId;

  @Length(min=2,max = 25, message = "유저 이름은 최소 2자 이상 최대 25자까지 입력해야 합니다.")
  private String userName;

  @Length(min=5, max = 30, message = "비밀번호는 최소 5자 이상 최대 30자까지 입력해야 합니다.")
  private String password;

  1) isMemberCheckBox => 체크박스 형태이고, True이면 대표 아이디 생성도 하려고 합니다. (편집됨) 
    false이면 userId, userName, password도 입력해야 돼요.
    체크박스 클릭하면 userId, userName, password도 입력하는 칸이 나오면 될 것 같아요.
  2) storeId=> 이것도 회원가입 아이디 중복 체크처럼. 체크하는 게 있어야돼요.
*/
interface AddStoreRequest {
  storeId: string | '';
  storeName: string | '';
  businessNum: number | '';
  phoneNumber: number | '';
  isMemberCheckBox: boolean;
  userId?: string | '';
  userName?: string | '';
  password?: string | '';
}
const AddStoreContainer = () => {
  const setErrorState = useSetRecoilState(errorOrYesModalState);
  const storeInfoValue = useRecoilValue(storeInfo);
  const [inputResult, setInputResult] = useState<AddStoreRequest>({
    storeId: '',
    storeName: '',
    businessNum: '',
    phoneNumber: '',
    isMemberCheckBox: false,
    userId: '',
    userName: '',
    password: '',
  });

  // 입력 핸들러
  const newInputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setInputResult((prevState) => ({
      ...prevState,
      [id]: value, // id가 password인 경우 password 필드를 업데이트
    }));
  };

  const checkToggleHandler = () => {
    setInputResult((prevState) => ({
      ...prevState,
      isMemberCheckBox: !inputResult.isMemberCheckBox,
    }));
  };

  const saveAddStoreBtn = () => {
    console.log(inputResult);
    if (
      inputResult.isMemberCheckBox &&
      ((inputResult.userId && inputResult.userId.length === 0) ||
        (inputResult.userName && inputResult.userName.length === 0) ||
        (inputResult.password && inputResult.password.length === 0))
    ) {
      setErrorState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '대표 아이디 설정',
        text: '대표 아이디를 설정할 경우 아이디, <br />이름, 비밀번호를 모두 입력해주셔야 합니다.',
        modalBG: true,
      });

      return;
    }
    let data: AddStoreRequest;

    if (!inputResult.isMemberCheckBox) {
      data = {
        storeId: inputResult.storeId,
        storeName: inputResult.storeName,
        businessNum: inputResult.businessNum,
        phoneNumber: inputResult.phoneNumber,
        isMemberCheckBox: false,
      };
    } else {
      data = inputResult;
    }

    console.log('data: ', data);
  };

  return (
    <div className="add_store_wrapper">
      <div className="input_form flex">
        <Form onSubmit={(e) => e.preventDefault()} className="form_flex">
          <Form.Control
            value={inputResult.storeId}
            type="text"
            id="storeId"
            maxLength={25}
            className="input_section flex_input"
            placeholder="지점 코드"
            onChange={(e) => newInputHandler(e)}
            autoComplete="off"
          />
        </Form>

        <Button className="check_store_id">
          <div>중복확인</div>
          {/* <img src={checkIcon} alt='체크 아이콘' className='icon'/> */}
        </Button>
      </div>

      <div className="input_form">
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Control
            value={inputResult.storeName}
            type="text"
            id="storeName"
            maxLength={25}
            className="input_section"
            placeholder="지점 이름"
            onChange={(e) => newInputHandler(e)}
            autoComplete="off"
          />
        </Form>
      </div>

      <div className="input_form">
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Control
            value={inputResult.businessNum}
            type="number"
            id="businessNum"
            maxLength={25}
            className="input_section"
            placeholder="사업자 번호"
            onChange={(e) => newInputHandler(e)}
            autoComplete="off"
          />
        </Form>
      </div>

      <div className="input_form">
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Control
            value={inputResult.phoneNumber}
            type="number"
            id="phoneNumber"
            maxLength={11}
            className="input_section"
            placeholder="휴대폰 번호"
            onChange={(e) => newInputHandler(e)}
            autoComplete="off"
          />
        </Form>
      </div>

      <Form className="switch_wrapper">
        <Form.Check // prettier-ignore
          type="switch"
          id="custom_switch"
          label="대표 아이디 설정"
          onChange={checkToggleHandler}
        />
      </Form>

      {inputResult.isMemberCheckBox && (
        <div className="slide_down">
          <div className="input_form">
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Control
                value={inputResult.userId}
                type="text"
                id="userId"
                maxLength={25}
                className="input_section"
                placeholder="유저 아이디"
                onChange={(e) => newInputHandler(e)}
                autoComplete="off"
              />
            </Form>
          </div>

          <div className="input_form">
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Control
                value={inputResult.userName}
                type="text"
                id="userName"
                maxLength={25}
                className="input_section"
                placeholder="유저 이름"
                onChange={(e) => newInputHandler(e)}
                autoComplete="off"
              />
            </Form>
          </div>
          <div className="input_form">
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Control
                value={inputResult.password}
                type="text"
                id="password"
                maxLength={30}
                className="input_section"
                placeholder="비밀번호"
                onChange={(e) => newInputHandler(e)}
                autoComplete="off"
              />
            </Form>
          </div>
        </div>
      )}

      <Button className="confirm_btn" onClick={saveAddStoreBtn}>
        저장
      </Button>
    </div>
  );
};

export default AddStoreContainer;
