import PerMachineContainer from '@components/perMachine/PerMachineContainer';
import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';
import React from 'react';

const PerMachine = () => {
  return (
    <div id="per_machine_container">
      <DashBoardHeader />
      <PerMachineContainer />
    </div>
  );
};

export default PerMachine;
