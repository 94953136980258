import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ClassNames } from '../../../node_modules/@types/react-date-range/index.d';
import { ITotalSalesItem, PAYMETHOD, TRANSACTION } from '@dataTypes/totalSalesTypes';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';

interface SalesDetailModalProps {
  show: boolean;
  handleClose: () => void;
  detailModalData: ITotalSalesItem;
}

const SalesDetailModal = ({ show, handleClose, detailModalData }: SalesDetailModalProps) => {
  const storeInfoState = useRecoilValue(storeInfo);
  const formatTransactionDate = (transactionDate: string) => {
    const datePart = transactionDate.slice(0, 6); // "240601"
    const timePart = transactionDate.slice(6); // "000002"

    // 날짜 포맷팅
    const year = datePart.slice(0, 2); // "24"
    const month = datePart.slice(2, 4); // "06"
    const day = datePart.slice(4, 6); // "01"
    const formattedDate = `${year}-${month}-${day}`; // "24-06-01"

    // 시간 포맷팅
    const hours = timePart.slice(0, 2); // "00"
    const minutes = timePart.slice(2, 4); // "00"
    const seconds = timePart.slice(4, 6); // "02"
    const formattedTime = `${hours}:${minutes}:${seconds}`; // "00:00:02"

    return [formattedDate, formattedTime];
  };

  const [formattedDate, formattedTime] = formatTransactionDate(detailModalData?.transactionDate);

  const formattedMethod = (method: PAYMETHOD, status: TRANSACTION) => {
    if (status === TRANSACTION.C) {
      switch (method) {
        case PAYMETHOD.CARD:
          return '카드 취소';
        case PAYMETHOD.CASH:
          return '현금 취소';
        case PAYMETHOD.ETC:
          return '기타 취소';
        default:
          return '알 수 없음 취소';
      }
    } else {
      switch (method) {
        case PAYMETHOD.CARD:
          return '카드';
        case PAYMETHOD.CASH:
          return '현금';
        case PAYMETHOD.ETC:
          return '기타';
        default:
          return '알 수 없음';
      }
    }
  };

  const formattedTransaction = (method: TRANSACTION) => {
    switch (method) {
      case TRANSACTION.A:
        return '승인';
      case TRANSACTION.C:
        return '취소';
      case TRANSACTION.E:
        return '에러';
      default:
        return '알 수 없음';
    }
  };

  const addCommas = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <Offcanvas id="sales_detail_modal_wrapper" show={show} onHide={handleClose} placement="bottom">
      <Offcanvas.Header closeButton className="sales_detail_header">
        <Offcanvas.Title>상세보기</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="sales_detail_body">
        <div className="sales_date">
          <span className="title">매출일자</span>
          <span className="text">{formattedDate}</span>
        </div>
        <div className="sales_date">
          <span className="title">매출시간</span>
          <span className="text">{formattedTime}</span>
        </div>
        <div className="sales_date time">
          <span className="title">지점명</span>
          <span className="text">{storeInfoState.currentStore}</span>
        </div>
        <div className="sales_date">
          <span className="title">결제수단</span>
          <span className="text">
            {formattedMethod(
              detailModalData?.method || PAYMETHOD.CARD,
              detailModalData.transactionStatus,
            )}
          </span>
        </div>
        <div className="sales_date">
          <span className="title">매입사</span>
          <span className="text">{detailModalData?.cardCompany}</span>
        </div>

        <div className="sales_date">
          <span className="title">승인번호</span>
          <span className="text">{(detailModalData && detailModalData.approvalNum) || '0'}</span>
        </div>

        <div className="sales_date info">
          <span className="title">카드번호</span>
          <span className="text">{detailModalData?.cardNum}</span>
        </div>
        <div className="sales_date">
          <span className="title">금액</span>
          <span className="text">{detailModalData && addCommas(detailModalData.amount || 0)}</span>
        </div>
        <div className="sales_date">
          <span className="title">부가세</span>
          <span className="text">{detailModalData && addCommas(detailModalData.tax || 0)}</span>
        </div>

        <div className="sales_date">
          <span className="title">단말기번호</span>
          <span className="text">{detailModalData?.catId}</span>
        </div>
        <div className="sales_date">
          <span className="title">거래유형</span>
          <span className="text">
            {(detailModalData && formattedTransaction(detailModalData?.transactionStatus)) || ''}
          </span>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SalesDetailModal;
