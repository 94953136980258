import React from 'react';
import DashBoardLeftNavBar from '@components/salesDashBoard/DashBoardLeftNavBar';
import DashBoardRightContainer from '@components/salesDashBoard/DashBoardRightContainer';
import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';

const SalesDashboard = () => {
  return (
    <div id="sales_dashboard_container">
      <DashBoardHeader />
      {/*<DashBoardLeftNavBar />*/}
      <DashBoardRightContainer />
    </div>
  );
};

export default SalesDashboard;
