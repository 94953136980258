import React, { useCallback } from 'react';
import searchIcon from '@assets/icon/search_icon.png';
import { Button } from 'react-bootstrap';
import { debounce } from 'lodash';
import { AUTH } from '@dataTypes/authTypes';
import Cookies from 'js-cookie';

interface MainHeaderProps {
  setAddMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
  inputSearchVal: string;
  setInputSearchVal: React.Dispatch<React.SetStateAction<string>>;
}

const MainHeader = ({ setAddMemberModal, inputSearchVal, setInputSearchVal }: MainHeaderProps) => {
  const searchUserHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    getDebounce(event.currentTarget.value);
  };
  const authority = Cookies.get('smartlab_authority');

  const getDebounce = debounce((data) => {
    setInputSearchVal(data);
  }, 500);

  return (
    <>
      <div className="text_wrapper">
        <div className="title_wrapper">
          <h2 className="title">회원 관리</h2>
          <span className="sub_text">권한 수정을 원하시는 회원을 클릭해주세요.</span>
        </div>

        <div className="add_search_wrapper">
          {authority !== AUTH.USER && (
            <Button className="add" onClick={() => setAddMemberModal(true)}>
              회원 추가
            </Button>
          )}
        </div>
      </div>

      <div className="search_user_wrapper">
        <div className="icon_wrapper">
          <img src={searchIcon} className="search_icon" alt="검색 아이콘" />
        </div>
        <input
          type="text"
          className="search_input"
          onChange={searchUserHandler}
          placeholder="이름 또는 휴대폰 번호를 입력해주세요."
        />
      </div>
    </>
  );
};

export default MainHeader;
