import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Offcanvas, OverlayTrigger, Popover } from 'react-bootstrap';
import DashBoardLeftNavBar from '@components/salesDashBoard/DashBoardLeftNavBar';
import loginUserIcon from '@assets/icon/user_icon.png';
import hamburgerIcon from '@assets/icon/hamburger_btn_icon.png';
import cctvIcon from '@assets/icon/cctv_icon(white).png';
import iotIcon from '@assets/icon/iot_icon(white).png';
import { useNavigate } from 'react-router-dom';
import { logoutRequest } from '@apis/signIn';
import { useRecoilState } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { AUTH } from '@dataTypes/authTypes';
import { changeStore, getUserMainData } from '@apis/salesDashboard';
import { IStoreResponseData, IStoreChangeData } from '@dataTypes/userInfo';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SALESDASHBOARD } from './queryKey/queryKey';
import Cookies from 'js-cookie';
import DashBoardNav from './DashBoardNav';

interface DashBoardHeaderProps {
  displayNone?: boolean;
}

const DashBoardHeader = ({ displayNone }: DashBoardHeaderProps) => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [currentStoreInfo, setCurrentStoreInfo] = useRecoilState(storeInfo);
  const queryClient = useQueryClient();

  const logoutHandler = async () => {
    const response = await logoutRequest();
    if (response) {
      setCurrentStoreInfo({
        currentStore: '',
        currentAuthority: '',
        currentStoreId: undefined,
        storeLists: [],
      });
      // queryClient.invalidateQueries();
      navigation('/signin');
    } else {
      console.error('logoutHandler error');
    }
  };

  //첫 페이지 진입 시
  useEffect(() => {
    const authority = Cookies.get('smartlab_authority');
    if (
      // authority !== AUTH.MASTER &&
      currentStoreInfo.currentStore === '' &&
      currentStoreInfo.currentAuthority === '' &&
      currentStoreInfo.currentStoreId === undefined &&
      currentStoreInfo.storeLists.length === 0
    ) {
      setShouldFetch(true);
    } else {
      setShouldFetch(false);
    }
  }, []);

  const {
    data: getCurrentListsData,
    isLoading: getInitDataLoading,
    isFetching: getInitDataFetching,
  } = useQuery<IStoreResponseData>(
    [SALESDASHBOARD.DASHBOARDCURRENTLISTS],
    () => getUserMainData(),
    {
      enabled: shouldFetch,
    },
  );

  useEffect(() => {
    if (getCurrentListsData && shouldFetch) {
      setCurrentStoreInfo({
        currentStore: getCurrentListsData.currentStore,
        currentAuthority: getCurrentListsData.currentAuthority,
        currentStoreId: getCurrentListsData.currentStoreId,
        storeLists: getCurrentListsData.storeLists,
      });
    }
  }, [getCurrentListsData, shouldFetch]);

  const changeIndexHandler = async (storeId: number) => {
    const response: IStoreChangeData = await changeStore(storeId);
    if (response) {
      setCurrentStoreInfo((prevState) => ({
        ...prevState,
        currentStore: response.currentStore,
        currentAuthority: response.currentAuthority,
        currentStoreId: response.storeId,
      }));
    }
  };

  const formattedAuth = () => {
    const auth = currentStoreInfo.currentAuthority;
    if (auth === AUTH.MASTER) {
      return '마스터';
    } else if (auth === AUTH.MANAGER) {
      return '매니저';
    } else {
      return '유저';
    }
  };

  return (
    <>
      <div className="dashboard_header">
        <div onClick={handleShow}>
          <img className="hamburger_btn" src={hamburgerIcon} alt="햄버거 버튼" />
        </div>

        {/* <div className="current_store_wrapper">
          <div className="store_name">{currentStoreInfo.currentStore}</div>
          <div className="store_auth">{formattedAuth()}</div>
        </div> */}

        <div className="iot_cctv_wrapper">
          {currentStoreInfo.currentAuthority !== AUTH.USER}
          <Button className="cctv_btn_wrapper" onClick={() => navigation('/admin/cctv')}>
            <img src={cctvIcon} alt="cctv 아이콘" className="icon" />
            <div className="text">CCTV</div>
          </Button>
          <Button className="cctv_btn_wrapper" onClick={() => navigation('/admin/hejHome')}>
            <img src={iotIcon} alt="cctv 아이콘" className="icon" />
            <div className="text">IOT</div>
          </Button>

          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover id={`popover-positioned-bottom`} className="custom_popover">
                <Popover.Body>
                  <div className="go_user_info_nav" onClick={() => navigation('/admin/mypage')}>
                    {/* <img className="nav_icon" alt="로그아웃 아이콘" src={logoutIcon} /> */}
                    <div className="title">마이페이지</div>
                  </div>
                  <div className="login_nav" onClick={logoutHandler}>
                    {/* <img className="nav_icon" alt="로그아웃 아이콘" src={logoutIcon} /> */}
                    <div className="title">로그아웃</div>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <div className="login_btn">
              <img className="login_icon" src={loginUserIcon} alt="사용자 아이콘" />
            </div>
          </OverlayTrigger>
        </div>
      </div>

      {!displayNone && (
        <>
          <div className="header_section">
            <Dropdown className="custom_dropdown">
              <Dropdown.Toggle className="reload_wrapper" variant="warning">
                <div className="store_auth_wrapper">
                  <span>{currentStoreInfo?.currentStore}</span>
                  <span className="store_auth">{formattedAuth()}</span>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {currentStoreInfo.storeLists?.map((list, index) => (
                  <Dropdown.Item
                    href=""
                    key={list.storeId}
                    onClick={() => changeIndexHandler(list.storeId)}
                  >
                    {list.storeName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <DashBoardNav />
        </>
      )}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Body id="offcanvas_container">
          <DashBoardLeftNavBar />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DashBoardHeader;
