import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';

const TotalListsSkeleton = () => {
  const daysArr = Array.from({ length: 42 }, (_, index) => index + 1);

  return (
    <>
      <ul className="total_list_wrapper skeleton">
        <li className="total_list">
          <div className="title_wrapper">
            <span className="date"></span>
            <span className="sub_text today">오늘</span>
          </div>
          <div className="money"></div>
        </li>

        <li className="total_list">
          <div className="title_wrapper">
            <span className="date"></span>
            <span className="sub_text">어제</span>
          </div>
          <div className="money with_section">
            <div className="left_section">
              <div className="yesterday_daily_money"></div>
              <div className="increase_percent_wrapper">
                <div className="percent"></div>
              </div>
            </div>

            <div className="right_section">
              <span className="sub_text"></span>
              <div className="month_ago">
                <span></span>
              </div>
            </div>
          </div>
        </li>

        <li className="total_list">
          <div className="title_wrapper">
            <span className="date"></span>
            <span className="sub_text">이번달</span>
          </div>
          <div className="money"></div>
        </li>

        <li className="total_list">
          <div className="title_wrapper">
            <span className="date"></span>
            <span className="sub_text">지난달</span>
          </div>
          <div className="money"></div>
        </li>
      </ul>

      <div className="month_sales_container">
        <div className="change_month_wrapper skeleton">
          <IoIosArrowForward className="left_arrow disabled" size={'1.5rem'} />
          <span className="month">
            <div className="month_skeleton">0</div>
          </span>
          <IoIosArrowForward className={`right_arrow disabled`} size={'1.5rem'} />
        </div>
      </div>

      <div className="month_total_lists_container skeleton">
        <div className="sales_wrapper purple">
          <span className="title">총 매출</span>
        </div>

        <div className="sales_wrapper red">
          <span className="title">총 매출</span>
        </div>

        <div className="sales_wrapper green">
          <span className="title">총 매출</span>
        </div>

        <div className="sales_wrapper orange">
          <span className="title">총 매출</span>
        </div>
      </div>
    </>
  );
};

export default TotalListsSkeleton;
