import CommonHeader from '@components/common/CommonHeader';
import MyPageMain from '@components/myPage/MyPageMain';
import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';
import React from 'react';

const MyPage = () => {
  return (
    <div id="my_page_container">
      {/* <DashBoardHeader displayNone={true} /> */}
      <CommonHeader>마이페이지</CommonHeader>
      <MyPageMain />
    </div>
  );
};

export default MyPage;
