import { atom } from 'recoil';
import { IStoreResponseData } from '@dataTypes/userInfo';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const storeInfo = atom<IStoreResponseData>({
  key: 'storeInfo',
  default: { currentStore: '', currentAuthority: '', currentStoreId: undefined, storeLists: [] },
  effects_UNSTABLE: [persistAtom],
});

export default storeInfo;
