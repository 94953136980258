import React, { useEffect, useState } from 'react';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import errorImg from '@assets/img/error_img.png';
import Cookies from 'js-cookie';
import axios, { AxiosError } from 'axios';
import { useSetRecoilState } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { logoutRequest } from '@apis/signIn';

const ErrorPage = () => {
  const error = useRouteError();
  const navigation = useNavigate();
  const [count, setCount] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (count === 0 && process.env.NODE_ENV === 'production') {
      if (error && isRouteErrorResponse(error)) {
        if (error.status === 404 || error.status === 403) {
          navigation(-1);
        } else if (error.status === 401) {
          navigation('/signin');
        } else {
          navigation('/');
        }
      } else {
        navigation('/');
      }
    }
  }, [count, error, navigation]);

  let errorStatus: number | string | undefined = '';

  if (isRouteErrorResponse(error)) {
    errorStatus = error.status;
  } else if (axios.isAxiosError(error) && error.response) {
    errorStatus = error.response.status;
  } else {
    errorStatus = '';
  }

  if (errorStatus === 404) {
    return (
      <div id="error_container">
        <div className="text_wrapper">
          <div className="error_status">404</div>
          <h1 className="error_title">원하시는 페이지를 찾을 수 없습니다.</h1>
          <h2 className="error_sub_text">
            찾으시는 페이지의 주소가 잘못 입력되었거나,
            <br /> 주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
          </h2>
          <h3 className="error_back_text">{`${count}초 후 이전 페이지로 돌아갑니다.`}</h3>
        </div>
        <img src={errorImg} alt="에러 이미지" className="error_img" />
      </div>
    );
  } else if (errorStatus === 401) {
    return (
      <div id="error_container">
        <div className="text_wrapper">
          <div className="error_status">401</div>
          <h1 className="error_title">인증되지 않은 사용자 입니다.</h1>
          <h2 className="error_sub_text">
            로그인 정보가 유효하지 않거나,
            <br /> 로그인 유효 시간이 경과하였습니다.
          </h2>
          <h3 className="error_back_text">{`${count}초 후 로그인 페이지로 돌아갑니다.`}</h3>
        </div>
        <img src={errorImg} alt="에러 이미지" className="error_img" />
      </div>
    );
  } else if (errorStatus === 403 || errorStatus === 400) {
    return (
      <div id="error_container">
        <div className="text_wrapper">
          <div className="error_status">403</div>
          <h1 className="error_title">권한이 없어 요청이 거부되었습니다.</h1>
          <h2 className="error_sub_text">
            관리자에게 권한을 요청하거나,
            <br /> 해당 기능의 권한 여부를 확인해주세요.
          </h2>
          <h3 className="error_back_text">{`${count}초 후 로그인 페이지로 돌아갑니다.`}</h3>
        </div>
        <img src={errorImg} alt="에러 이미지" className="error_img" />
      </div>
    );
  } else {
    return (
      <div id="error_container">
        <div className="text_wrapper">
          <div className="error_status">ERROR</div>
          <h1 className="error_title">알 수 없는 오류입니다.</h1>
          <h2 className="error_sub_text">
            로그아웃 후 재시도 해주세요
            <br /> 해당 오류가 계속되면 관리자에게 문의해주세요.
          </h2>
          <h3 className="error_back_text">{`${count}초 후 로그인 페이지로 돌아갑니다.`}</h3>
        </div>
        <img src={errorImg} alt="에러 이미지" className="error_img" />
      </div>
    );
  }
};

export default ErrorPage;
