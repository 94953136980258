import React, { useState, useEffect } from 'react';
import loadingSpinner from '@assets/gif/spnnier(gray).gif';
import endIdCheckIcon from '@assets/icon/check(black).png';

const EndIdCheck = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <img className="loading_spinner" src={loadingSpinner} alt="Loading Spinner" />
      ) : (
        <img className="check" src={endIdCheckIcon} alt="End ID Check Icon" />
      )}
    </>
  );
};

export default EndIdCheck;
