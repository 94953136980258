import React from 'react';
import { Button, Form } from 'react-bootstrap';

interface InputIdProps {
  inputId: string;
  inputIdHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const InputId = ({ inputId, inputIdHandler }: InputIdProps) => {
  return (
    <div className="input_id_wrapper">
      <div className="main_title">비밀번호를 찾고자 하는 아이디를 입력해주세요.</div>
      <div className="input_form flex">
        <Form onSubmit={(e) => e.preventDefault()} className="form_flex">
          <Form.Control
            value={inputId}
            type="text"
            id="storeId"
            maxLength={25}
            className="input_section flex_input"
            placeholder="아이디"
            onChange={(e) => inputIdHandler(e)}
            autoComplete="off"
          />
        </Form>

        <Button className="check_store_id">
          <div>다음</div>
        </Button>
      </div>
    </div>
  );
};

export default InputId;
