import React, { useEffect, useState } from 'react';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import TypeOneText from './agreeConstant/TypeOneText';
import TypeTwoText from './agreeConstant/TypeTwoText';
import TypeThreeText from './agreeConstant/TypeThreeText';
import { useRecoilState, useSetRecoilState } from 'recoil';

interface SignUpCheckRadioProps {
  agreeResult: { one: boolean; two: boolean; three: boolean };
  setAgreeResult: React.Dispatch<
    React.SetStateAction<{ one: boolean; two: boolean; three: boolean }>
  >;
}

const SignUpCheckRadio = ({ agreeResult, setAgreeResult }: SignUpCheckRadioProps) => {
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState<React.JSX.Element>(<></>);
  const [agreeBtn, setAgreeBtn] = useState('');

  const handleClose = () => setShow(false);

  const clickShowText = (type: string) => {
    setShow(true);

    setAgreeBtn(type);

    switch (type) {
      case 'one':
        setShowText(<TypeOneText />);
        break;
      case 'two':
        setShowText(<TypeTwoText />);
        break;
      case 'three':
        setShowText(<TypeThreeText />);
        break;
      default:
        setShowText(<></>);
    }
  };

  const notAgreeBtnHandler = () => {
    setAgreeResult((prevState) => ({
      ...prevState,
      [agreeBtn]: false,
    }));

    setShow(false);
  };

  const agreeBtnHandler = () => {
    setAgreeResult((prevState) => ({
      ...prevState,
      [agreeBtn]: true,
    }));

    setShow(false);
  };

  const clickHandler = (type: string) => {
    setAgreeResult((prevState) => ({
      ...prevState,
      [type]: !prevState[type as keyof typeof agreeResult],
    }));
  };

  const allAgreeHandler = () => {
    if (agreeResult.one && agreeResult.two && agreeResult.three) {
      setAgreeResult({
        one: false,
        two: false,
        three: false,
      });
    } else {
      setAgreeResult({
        one: true,
        two: true,
        three: true,
      });
    }
  };

  return (
    <section id="check_radio_wrapper">
      <div className="sign_up_title">약관동의</div>
      <ul className="radio_wrapper">
        <li className="check_type_wrapper">
          <Form.Check
            checked={agreeResult.one}
            onChange={() => clickHandler('one')}
            type={'checkbox'}
            id={`check_id_type_1`}
            label={`서비스 이용약관 (필수)`}
          />
          <div className="open_text_modal" onClick={() => clickShowText('one')}>
            내용보기
          </div>
        </li>
        <li className="check_type_wrapper">
          <Form.Check
            checked={agreeResult.two}
            onChange={() => clickHandler('two')}
            type={'checkbox'}
            id={`check_id_type_2`}
            label={`개인정보 수집 및 이용 (필수)`}
          />
          <div className="open_text_modal" onClick={() => clickShowText('two')}>
            내용보기
          </div>
        </li>
        <li className="check_type_wrapper check_type_sub_wrapper">
          <div className="type_3_title">
            <Form.Check
              checked={agreeResult.three}
              onChange={() => clickHandler('three')}
              type={'checkbox'}
              id={`check_id_type_3`}
              label={`이용권 이벤트 알림 선택 동의(선택)`}
            />
            <div className="open_text_modal" onClick={() => clickShowText('three')}>
              내용보기
            </div>
          </div>
          <span className="type_3_sub_text">
            ※ 이용권/이벤트 알림 동의시 향후 마케팅 이용권 등 혜택을 받을 수 있습니다.
          </span>
        </li>
        <li className="check_type_wrapper">
          <Form.Check
            checked={agreeResult.one && agreeResult.two && agreeResult.three}
            type={'checkbox'}
            id={`check_id_type_4`}
            label={`전체 동의`}
            onClick={allAgreeHandler}
            readOnly={true}
          />
        </li>
      </ul>

      <Offcanvas id="offcanvas_signUp" show={show} onHide={handleClose} placement={'end'}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>개인정보 수집 및 이용 동의</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body id="text">{showText}</Offcanvas.Body>
        <div className="btn_wrapper">
          <Button className="no_agree_btn" onClick={notAgreeBtnHandler}>
            동의하지 않음
          </Button>
          <Button className="agree_btn" onClick={agreeBtnHandler}>
            동의
          </Button>
        </div>
      </Offcanvas>
    </section>
  );
};

export default SignUpCheckRadio;
