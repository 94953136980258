import React, { useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import excelLogo from '@assets/logo/excel_logo.png';
import { Button, Carousel } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ISummaryDayItem } from '@dataTypes/dashBoardSummary';
import storeInfo from '../../recoil/storeInfo/atom';
import { useRecoilValue } from 'recoil';

interface MonthSalesProps {
  month: Date;
  setMonth: React.Dispatch<React.SetStateAction<Date>>;
  excelData: ISummaryDayItem[];
}

const MonthSales = ({ month, setMonth, excelData }: MonthSalesProps) => {
  const compareDate = month.toISOString().slice(0, 7) >= new Date().toISOString().slice(0, 7);
  const storeInfoState = useRecoilValue(storeInfo);

  const nextMonthHandler = () => {
    if (compareDate) {
      return;
    }
    const newMonth = new Date(month);
    newMonth.setDate(1); // set to the 1st of the month to avoid end of month issues
    newMonth.setMonth(newMonth.getMonth() + 1);
    setMonth(newMonth);
  };

  const prevMonthHandler = () => {
    const newMonth = new Date(month);
    newMonth.setDate(1); // set to the 1st of the month to avoid end of month issues
    newMonth.setMonth(newMonth.getMonth() - 1);
    setMonth(newMonth);
  };

  const formatDataForExcel = (data: ISummaryDayItem[]) => {
    return data
      .sort((a, b) => +a.date.replaceAll('-', '') - +b.date.replaceAll('-', ''))
      .map((item) => ({
        날짜: item.date,
        카드: item.card.toLocaleString('ko-KR'),
        현금: item.cash.toLocaleString('ko-KR'),
        기타: item.etc.toLocaleString('ko-KR'),
      }));
  };

  const handleDownload = () => {
    // 데이터에 맞는 워크시트 생성

    const worksheet = XLSX.utils.json_to_sheet(formatDataForExcel(excelData));

    // 워크북 생성 및 워크시트 추가
    const workbook = XLSX.utils.book_new();
    const sheetName = `${month.getMonth() + 1}월`;
    console.log('sheetName: ', sheetName);

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // 엑셀 파일 생성
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // 파일 저장
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = `${storeInfoState.currentStore} ${month.getMonth() + 1}월 매출.xlsx`;
    console.log('fileName: ', fileName);

    saveAs(blob, fileName);
  };

  return (
    <div className="month_sales_container">
      <div className="change_month_wrapper">
        <IoIosArrowForward onClick={prevMonthHandler} className="left_arrow" size={'1.5rem'} />
        <span className="month">{month.toISOString().slice(0, 7)}</span>
        <IoIosArrowForward
          onClick={nextMonthHandler}
          className={`right_arrow ${compareDate ? 'disabled' : ''}`}
          size={'1.5rem'}
        />
      </div>

      <div className="sales_excel_wrapper" onClick={handleDownload}>
        <img className="logo" src={excelLogo} alt="엑셀 로고" />
        <span className="text">월 매출 다운로드</span>
      </div>
    </div>
  );
};

export default MonthSales;
