import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';

interface InputPhoneNumberProps {
  inputPhone: string;
  phoneInputHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const InputPhoneNumber = ({ inputPhone, phoneInputHandler }: InputPhoneNumberProps) => {
  const [showAuthCode, setShowAuthCode] = useState(false);
  const setErrorState = useSetRecoilState(errorOrYesModalState);
  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [authCode, setAuthCode] = useState('');

  useEffect(() => {
    let countdown: any;

    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
      setIsTimerActive(false); // Stop the timer when it reaches 0
    }

    return () => clearInterval(countdown); // Cleanup the interval
  }, [isTimerActive, timer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const sendSMSHandler = async () => {
    const regex = /^\d{11}$/;

    if (!regex.test(inputPhone)) {
      setErrorState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '휴대폰 번호 11자리를 입력해주세요',
        modalBG: true,
      });

      return;
    }

    //휴대폰 번호 일치하는지 확인
    if (inputPhone.trim() !== '01039094145') {
      setErrorState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '입력하신 정보와 휴대폰 번호가 일치하지 않습니다.',
        modalBG: true,
      });

      return;
    }

    // const response = await sendSMSAsync(inputValue.phone);
    let response = true;
    if (response) {
      setErrorState({
        status: true,
        imgType: imgTypes.YES,
        title: '인증번호가 전송되었습니다',
        text: '인증번호 6자리를 입력해주세요.',
        modalBG: true,
      });

      setShowAuthCode(true);
      setIsTimerActive(true);
      setTimer(120);
    }
  };

  const checkSMSInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
    setAuthCode(sanitizedValue);
  };

  return (
    <div>
      <div>회원정보에 등록한 휴대전화로 인증 (+** 1*-3***-4***)</div>
      <div>
        회원정보에 등록한 휴대전화 번호와 입력한 휴대전화 번호가 같아야, 인증번호를 받을 수
        있습니다.
      </div>

      <div className="input_form">
        <div className="input_wrapper with_flex">
          <Form.Control
            value={inputPhone}
            type="text"
            id="phone_number"
            maxLength={11}
            className="input_section"
            placeholder="휴대폰 번호"
            onChange={phoneInputHandler}
          />
          <Button className="check_id_btn" onClick={sendSMSHandler} disabled={showAuthCode}>
            {!showAuthCode ? '인증하기' : '전송 완료'}
          </Button>
        </div>

        {!showAuthCode && (
          <div className="auth_code_wrapper">
            <div className="input_wrapper">
              <Form.Control
                value={authCode}
                type="text"
                id="check_form"
                maxLength={6}
                className="input_section"
                placeholder="인증 번호 6자리를 입력해주세요."
                onChange={checkSMSInputHandler}
              />
            </div>
            <div className="time">{formatTime(timer)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputPhoneNumber;
