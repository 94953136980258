import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { useHejHomeList } from '@hooks/hejHome/useHejHomeList';
import { addIOT } from '@apis/hejHome';
import { hejHomDeviceListConstant } from '../../constants/hejHomeDeviceListConstant';
import { IDevice } from '@dataTypes/hejHomeTypes';
import Airconditioner from './Airconditioner';
import PlugMini from './PlugMini';
import CurrentTemperature from './CurrentTemperature';
import batteryHighIcon from '@assets/icon/battery_high_icon(black).png';
import batteryMediumIcon from '@assets/icon/battery_medium_icon(black).png';
import batteryLowIcon from '@assets/icon/battery_low_icon(black).png';
import SwitchThreeBulb from './SwitchThreeBulb';
import HejHomeLogo from '@assets/logo/hejhome_logo.png';
import { BsCheck } from 'react-icons/bs';

export interface IDeviceProps {
  list: IDevice;
}

const clientId = '5e351c2037044d31b1080d053a8e6eb3';
const redirectURI = 'https://gosca.o-r.kr/admin/hejHome';

const HejHome = () => {
  const { checkIOTResponseData, deviceList, temperatureData, checkIOTRefetch } = useHejHomeList();
  const storeInfoState = useRecoilValue(storeInfo);
  const currentUrl = window.location.href;

  // URL을 URL 객체로 변환
  const urlObject = new URL(currentUrl);

  // URLSearchParams 객체를 사용하여 쿼리 파라미터를 추출
  const queryParams = new URLSearchParams(urlObject.search);

  // 'code' 파라미터의 값을 얻습니다
  const code = queryParams.get('code');

  console.log(code);

  //헤이홈 로그인 후 code를 보내서 토큰 얻기
  const handleToken = async () => {
    if (code) {
      const result = await addIOT(code);

      if (result) {
        // 성공적으로 토큰을 받은 경우의 처리
        console.log('토큰을 성공적으로 받았습니다:', result);
        checkIOTRefetch();
      } else {
        // 토큰을 받지 못한 경우의 처리
        console.error('코드 등록에 실패했습니다.');
      }
    } else {
      console.error('URL에 코드가 없습니다.');
    }
  };

  if (code) {
    handleToken();
  }

  const goHejHome = () => {
    const url = 'https://goqual.io/login/openapi';
    const params = [
      'response_type=code',
      `client_id=${clientId}`,
      'scope=openapi',
      `redirect_uri=${redirectURI}`,
    ].join('&');

    const fullUrl = `${url}?${params}`;

    // 인증 서버로 리디렉션
    window.location.href = fullUrl;
  };

  const goHejhomeQuestionPage = () => {
    const url = 'https://m.hej.life/board/faq/list.html'; // 새 창에서 열고 싶은 URL
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

    if (newWindow) {
      newWindow.opener = null; // 새 창이 부모 창을 참조하지 않도록 설정
    }
  };

  const formattedTemperature = temperatureData?.deviceState.temperature
    ? (temperatureData.deviceState.temperature / 100).toFixed(1) // 온도를 100으로 나누고 소숫점 첫째까지
    : 'N/A';

  const formattedHumidity = temperatureData?.deviceState.humidity
    ? (temperatureData.deviceState.humidity / 100).toFixed() // 습도를 100으로 나누기
    : 'N/A';

  const formattedBattery = () => {
    const currentBattery = temperatureData?.deviceState.battery;

    if (currentBattery) {
      if (70 <= currentBattery) {
        return batteryHighIcon;
      } else if (30 <= currentBattery && currentBattery < 70) {
        return batteryMediumIcon;
      } else {
        return batteryLowIcon;
      }
    } else {
      console.error('formattedBattery error');
      return batteryHighIcon;
    }
  };

  if (!storeInfoState.currentStoreId) {
    return <div>스토어 값이 없음</div>;
  }

  if (!checkIOTResponseData?.hasAccessToken) {
    return (
      <div className="hejhome_first_login_wrapper">
        <img src={HejHomeLogo} alt="헤이홈 로고" />
        <div className="title">스마트폰으로 언제 어디서든 간편하게 제어하는 헤이홈입니다.</div>
        <div className="caution_wrapper">
          <div className="text">
            <div className="icon_wrapper">
              <BsCheck size={'1.5rem'} color="#00a872" />
            </div>
            <div>최초 연동 시, 헤이홈 어플리케이션을 다운 받아 회원 가입을 해야 합니다.</div>
          </div>
          <div className="text">
            <div className="icon_wrapper">
              <BsCheck size={'1.5rem'} color="#00a872" />
            </div>
            <div>
              <div>문의 전화: 1644-7098</div>

              <div className="question_center_wrapper">
                <span>문의 센터:</span>
                <div className="go_question" onClick={goHejhomeQuestionPage}>
                  헤이홈 문의센터
                </div>
              </div>
            </div>
          </div>
          <div className="text">
            <div className="icon_wrapper">
              <BsCheck size={'1.5rem'} color="#00a872" />
            </div>
            <div>헤이홈의 모든 제품은 기본적으로 2.4GHz 신호 연결을 지원하고 있습니다</div>
          </div>
          <div className="text">
            <div className="icon_wrapper">
              <BsCheck size={'1.5rem'} color="#00a872" />
            </div>
            <div>반드시 헤이홈 어플리케이션에 기기를 연동해야 조작이 가능합니다.</div>
          </div>
        </div>
        <button onClick={goHejHome} className="go_hejhome_login_btn">
          로그인 하러 가기
        </button>
      </div>
    );
  }

  return (
    <div className="iot_list_wrapper">
      <div className="main_text_wrapper">
        <div className="title_wrapper">
          <h1 className="list_title">IOT 제어 시스템</h1>
          <div className="go_question" onClick={goHejhomeQuestionPage}>
            헤이홈 문의센터
          </div>
        </div>
        <h2 className="list_sub_text">헤이홈 기기를 등록하신 후 IOT 제어가 가능합니다.</h2>
      </div>

      <ul className="iot_list">
        <CurrentTemperature
          temperature={formattedTemperature}
          humidity={formattedHumidity.toString()}
          battery={formattedBattery()}
        />
        {deviceList?.length >= 1 ? (
          <>
            {/* 에어컨 */}
            {deviceList
              .filter((list) => list.deviceType === hejHomDeviceListConstant.IrAirconditioner)
              .map((list) => (
                <Airconditioner
                  key={list.id}
                  list={list}
                  currentTemperature={formattedTemperature}
                  currentHumidity={formattedHumidity.toString()}
                />
              ))}

            {/* 플러그Mini */}
            {deviceList
              .filter((list) => list.deviceType === hejHomDeviceListConstant.SmartPlugMini)
              .map((list) => (
                <PlugMini key={list.id} list={list} />
              ))}

            {deviceList
              .filter((list) => list.deviceType === hejHomDeviceListConstant.ZigbeeSwitch3)
              .map((list) => (
                <SwitchThreeBulb key={list.id} list={list} />
              ))}
          </>
        ) : (
          <div>등록된 기기가 없습니다.</div>
        )}
      </ul>
    </div>
  );
};

export default HejHome;
