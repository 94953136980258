import React from 'react';

const TypeOneText = () => {
  return (
    <>
      <p>개인정보의 수집 및 이용 목적</p>
      <div>SMARTLAB은 홈페이지에서 회원님의 가입시 아래 목적을 위해 개인정보를 수집합니다.</div>
      <ul>
        <li>- 서비스 가입, 서비스 제공, 예약/구매내역, 결제대금의 청구</li>
        <li>- 상담/불만/민원처리, 고지/안내사항 전달</li>
        <li>- 상품∙서비스 이용실적 정보 통계∙분석, 상품∙서비스 개선 및 추천</li>
        <li>- 불법/부정이용방지, 개인정보 유효기간제 준수</li>
      </ul>
      <p>개인정보 보유 항목</p>
      <ul>
        <li>
          아이디(이메일), 비밀번호, 이름, 휴대전화 번호, 서비스이용기록, IP address, 접속로그,
          Cookie, 결제정보
        </li>
        <li>현금환불요청시: 계좌번호 및 예금주명</li>
        <li>회원 탈퇴시 부정이용 이슈 방지를 위해 탈퇴 DB에서 7일 후 삭제</li>
        <li>
          관계법령에 따라 보존할 필요가 있는 경우 해당 법령에서 요구하는 기한까지 보관
          <br />
          (예: 구매 회원의 경우 5년간, IP의 경우 3개월)
        </li>
      </ul>
      <div>※ 위 개인정보 수집∙이용에 대한 동의를 거부할 경우 서비스 이용이 제한됩니다.</div>
    </>
  );
};

export default TypeOneText;
