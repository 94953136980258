import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const MyPageMain = () => {
  const navigation = useNavigate();
  return (
    <div className="my_page_main_container">
      <div className="user_info_wrapper">
        <span className="select_title">계정 보안</span>
        <div className="select_wrapper" onClick={() => navigation('/admin/changepassword')}>
          <span className="title">비밀번호 변경</span>
          <IoIosArrowForward size={'1.5rem'} className="arrow" />
        </div>
        <div className="select_wrapper" onClick={() => navigation('/admin/deleteaccount')}>
          <span className="title">회원 탈퇴</span>
          <IoIosArrowForward size={'1.5rem'} className="arrow" />
        </div>
      </div>
    </div>
  );
};

export default MyPageMain;
