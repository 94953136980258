import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPerMachineLists } from '@apis/perMachine';
import { machineListsData } from 'types/perMachineTypes';
import { PERMACHINE } from '@components/perMachine/queryKey/queryKey';

// 커스텀 훅 정의: 기계 목록 가져오기
export const useGetMachineLists = (storeId: number | undefined) => {
  // 기계 목록 가져오기
  const {
    data: getMachineListsData,
    isLoading: getMachineListsLoading,
    isFetching: getMachineListsFetching,
  } = useQuery<machineListsData[]>(
    [PERMACHINE.MACHINELISTS, storeId],
    () => getPerMachineLists(storeId),
    {
      enabled: !!storeId,
    },
  );

  return {
    getMachineListsData,
    getMachineListsLoading,
    getMachineListsFetching,
  };
};
